import { z } from 'zod';

import { propertyConditionStringNumberSchema } from '../../_schema/sourceGA4PropertiesFormBase';

// Conversions only get one property condition block
const propertyConditionBlockConversionSchema = z.object({
  name: z
    .string()
    .min(1, 'Name cannot be empty')
    .regex(
      /^[a-z0-9_]+[a-z0-9]$/,
      "Name must start with a letter and contain only letters, numbers, and underscores. (And can't end with an underscore)"
    ),
  propertyConditionBlock: z.object({
    propertyConditions: propertyConditionStringNumberSchema,
  }),
});

/**
 * DEFINTIION: Conversions
 */
export const sourceGA4BigQueryConversionsSchemaForm = propertyConditionBlockConversionSchema;

/**
 * Field info for the conversions form
 */
export const fieldInfoSourceGA4DBTConversions = {
  // Conversion Events
  propertyConditionBlock: {
    name: 'Property Condition Block',
    showLabel: false,
    showDescription: false,
    description: 'Define property conditions for the pipeline',
    fields: {
      propertyConditions: {
        showArrayLabel: false,
        name: '1. Setup conditions for your conversion.',
        fields: {
          conditionBlock: {
            name: 'When these conditions are true.',
            showLabel: false,
            fields: {
              andOr: {
                name: 'And/Or',
                description:
                  'Choose whether all conditions should match (AND) or any condition should match (OR)',
              },
              conditions: {
                name: 'Conditions',
                showLabel: false,
                fields: {
                  col: {
                    name: 'Column',
                  },
                  operator: {
                    name: 'Operator',
                  },
                  value: {
                    name: 'Value',
                  },
                },
              },
            },
          },
          value: {
            name: '2. When the conditions are true, this will be the value of your conversion.',
            description: 'The value to set when the condition is true',
            fields: {
              col: {
                name: 'Pick a column',
              },
              on_condition_true: {
                name: 'Static Value',
                // description: 'The value to set when the condition is true',
              },
              agg: {
                name: 'Aggregation',
                // description: 'The aggregation method to use',
              },
            },
          },
          compare: {
            name: '3. Compare to another value to get true/false.',
            description: 'Compare the value to the value below',
            fields: {
              operator: {
                name: 'Operator',
              },
            },
          },
        },
      },
    },
  },
};
