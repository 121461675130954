import Alert from 'components/Alert/Alert';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import { PricingTableDisplay } from './PricingTableDisplay';
import { usePricingTable } from './usePricingTable';

/**
 * PricingTable - Main pricing table for new subscriptions
 * @param {Object} props
 * @param {Array} props.initialTiers - Initial pricing tiers
 * @param {Array} props.sections - Table sections configuration
 */
export default function PricingTable({ initialTiers, sections }) {
  const {
    localTiers,
    billingCycle,
    isLoading,
    userLoading,
    error,
    userError,
    setBillingCycle,
    handleCheckout,
    utils,
  } = usePricingTable({
    initialTiers,
  });

  if (isLoading || !localTiers.length) {
    return (
      <div className="mt-4">
        <LoadingSpinner text="Loading plans" isFullWidth />
      </div>
    );
  }

  if (error || userError) {
    return (
      <Alert
        status="error"
        message="Failed to get account data so can't load pricing table. Please contact support."
      />
    );
  }

  if (userLoading) {
    return <div>Loading...</div>;
  }

  return (
    <PricingTableDisplay
      localTiers={localTiers}
      billingCycle={billingCycle}
      setBillingCycle={setBillingCycle}
      sections={sections}
      isLoading={isLoading}
      handleAction={handleCheckout}
      utils={utils}
      buttonClassName="bg-red-damask-600 text-white hover:bg-red-damask-500"
    />
  );
}
