import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageAccordionStrip from 'components/PageAccordionStrip/PageAccordionStrip';
import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';
import SourceGA4BigQueryPageDescription from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryPageDescription';
import SourceGA4BigQueryTitle from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryTitle';
import SourceGA4BigQueryFormStageColumnsToUnnest from 'pages/models/source_ga4_bigquery/FormPageColumnsToUnnest/components/SourceGA4BigQueryFormStageColumnsToUnnest';
import { FormStateProviderColumnsToUnnest } from 'pages/models/source_ga4_bigquery/FormPageColumnsToUnnest/components/SourceGA4BigQueryFormStateProvidersColumnsToUnnest';
import SourceGA4EventSample from 'pages/models/source_ga4_bigquery/FormPageColumnsToUnnest/components/SourceGA4EventSampleColumnsToUnnest';
import { useGetSourceGA4BigQueryByIdQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * Form page for configuring which GA4 columns to unnest
 */
const SourceGA4BigQueryFormPage = () => {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const pageTitle = isUpdate ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline';

  const [propertyId, setPropertyId] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState([]);

  const { data: sourceData } = useGetSourceGA4BigQueryByIdQuery(id, { skip: !isUpdate });

  useEffect(() => {
    if (sourceData) {
      const options = sourceData.property_ids.map((id) => ({ value: id, label: id }));
      setPropertyOptions(options);
      setPropertyId(sourceData.property_ids[0]);
    }
  }, [sourceData]);

  // If we need to fuck with form data across different levels then we can use FormStateProvider
  // to allow other elements to access the form data without having to pass through.

  const handleSuccess = useCallback(() => {
    // Handle what happens after successful form submission
  }, []);

  return (
    <PageLayout isItGrey>
      <FormStateProviderColumnsToUnnest pipelineId={pipelineId}>
        <div>
          <PageLayoutInnerStrip>
            <SourceGA4BigQueryTitle
              title={pageTitle}
              pipelineId={pipelineId}
              sourceGA4BigQueryID={id}
              showActions={isUpdate}
            />
          </PageLayoutInnerStrip>
          <PageLayoutInner>
            <div className="flex">
              <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="main" />
              <div className="flex-grow mt-5">
                <SourceGA4BigQueryPageDescription pageKey="columnsToUnnest" />
                <SourceGA4BigQueryFormSection>
                  <SourceGA4BigQueryFormStageColumnsToUnnest
                    entityId={id}
                    onSuccess={handleSuccess}
                    propertyId={propertyId}
                  />
                </SourceGA4BigQueryFormSection>
                <PageAccordionStrip title="Example GA4 Event Data" className="mt-4">
                  <SourceGA4EventSample
                    property_id={propertyId}
                    project_id={sourceData?.gcloud_project_id}
                    propertyOptions={propertyOptions}
                    onPropertyChange={setPropertyId}
                  />
                </PageAccordionStrip>
              </div>
            </div>
          </PageLayoutInner>
        </div>
      </FormStateProviderColumnsToUnnest>
    </PageLayout>
  );
};

export default SourceGA4BigQueryFormPage;
