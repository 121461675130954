import { isAddonPrice } from '../components/pricingUtils';

/**
 * Hook to extract and format subscription data
 */
export const useSubscriptionData = (subscription) => {
  const baseItem = subscription.items.find((item) => !isAddonPrice(item.price.id));
  const addonItems = subscription.items.filter((item) => isAddonPrice(item.price.id));

  const billingInterval = baseItem?.price?.billing_cycle?.interval;
  if (!billingInterval) {
    throw new Error('Billing cycle issues, please contact support');
  }

  const total = subscription.items.reduce((sum, item) => {
    return sum + parseInt(item.price.unit_price.amount) * item.quantity;
  }, 0);

  return {
    baseItem,
    addonItems,
    billingInterval,
    total,
    isTrialing: baseItem?.status === 'trialing',
    nextBillingDate: baseItem?.next_billed_at,
    trialEndDate: baseItem?.trial_dates?.ends_at,
  };
};
