import UserModelDisplay from 'components/_displayModel/user/UserModelDisplay';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * Page component for displaying user settings and information
 */
const UserSettingsPage = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">User Settings</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <UserModelDisplay />
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default UserSettingsPage;
