import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import useGA4TableSchema from 'pages/models/source_ga4_bigquery/_hooks/useGA4TableSchema';
import {
  fieldInfoSourceGA4DBTEventProperties,
  sourceGA4BigQueryEventPropertiesSchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageEventProperties/schema/sourceGA4DBTEventPropertiesSchema';
import { useGetDbtProjectFileQuery, usePartialUpdateDbtProjectFileMutation } from 'services/rtkApi';

const SourceGA4BigQueryFormEventProperties = ({ entityId, onSuccess, propertyId }) => {
  /**
   * This form should never be used with create.
   */
  const { id, pipelineId } = useParams();

  const isUpdate = Boolean(entityId);
  const formType = isUpdate ? 'update' : 'create';

  const mutationHook = isUpdate ? usePartialUpdateDbtProjectFileMutation : undefined;
  const queryHook = isUpdate ? useGetDbtProjectFileQuery : undefined;

  const onFieldChange = useCallback((formValues, changedFields) => {
    // console.log('formValues', formValues);
    // console.log('changedFields', changedFields);
  });

  /**
   * Go and get the form data
   */
  // Go and get the form data
  // const { formData, isLoading, error } = useGA4BigQueryFormData(pipelineId);
  // const foreignKeyOptions = useMemo(
  //   () => [
  //     {
  //       key: ['property_ids'],
  //       options: formData?.map((item) => ({ value: item.id, label: item.name })) || [],
  //     },
  //   ],
  //   [formData]
  // );

  // Go and get table schema for the form.
  const {
    pipelineData,
    tableSchema,
    isLoading: isLoadingTableSchema,
  } = useGA4TableSchema(pipelineId, 'ga4_base_events');

  // Format event properties for the form options. We only want tone
  const foreignKeyOptions = useMemo(
    () => [
      {
        key: ['hit_properties', '*', 'name'],
        options:
          tableSchema?.fields
            ?.filter((field) => field.name.startsWith('ep__'))
            ?.map((field) => ({
              value: field.name,
              label: `${field.name} (${field.type})`,
            })) || [],
      },
    ],
    [tableSchema]
  );

  return (
    <CrudForm
      entityId={entityId}
      key={`pipeline_${formType}`}
      queryHook={queryHook}
      formType={formType}
      mutationHook={mutationHook}
      schema={sourceGA4BigQueryEventPropertiesSchemaForm}
      fieldInfo={fieldInfoSourceGA4DBTEventProperties}
      onSuccess={onSuccess}
      warnOnUnsavedChanges
      initialValues={{ property_id: propertyId }}
      onFieldChange={onFieldChange}
      foreignKeyOptions={foreignKeyOptions}
    />
  );
};

export default SourceGA4BigQueryFormEventProperties;
