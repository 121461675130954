import { useMemo } from 'react';

import { convertJsonPathToString } from 'utils/index';

/**
 * Hook to convert numeric values to strings in conversion events
 * @param {Array} conversion_events - Array of conversion event objects
 * @returns {Array} - Processed conversion events with string values
 */
const useConvertCustomPropertiesValuesToString = (conversion_events) => {
  return useMemo(() => {
    if (!conversion_events?.length) return [];

    const paths = [
      [
        'propertyConditionBlock',
        'propertyConditions',
        '*',
        'conditionBlock',
        'conditions',
        '*',
        'value',
      ],
      ['propertyConditionBlock', 'propertyConditions', '*', 'value', 'on_condition_true'],
      ['propertyConditionBlock', 'propertyConditions', '*', 'compare', 'value'],
    ];

    return convertJsonPathToString(conversion_events, paths);
  }, [conversion_events]);
};

export default useConvertCustomPropertiesValuesToString;
