/**
 * Hook to manage subscription plan logic including state management,
 * API interactions, and business logic for plan changes and cancellations
 */
import { useRef, useState } from 'react';

import { useCancelPaddleSubscription } from 'hooks/paddle/useCancelPaddleSubscription';
import {
  useChangeSubscriptionItemMutation,
  useGetPaddleActivePlansQuery,
  useGetPaddleSubscriptionsQuery,
} from 'services/rtkApi';

import { formatPrice } from '../pricingUtils';

export function useManageYourPlan() {
  // State management
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRecentChanges, setHasRecentChanges] = useState(false);
  const messageRef = useRef({ message: '', type: '' });

  // API hooks
  const {
    data: subscriptions,
    isLoading: isLoadingSubscriptions,
    error,
    refetch,
  } = useGetPaddleSubscriptionsQuery();

  const [changeSubscriptionItem] = useChangeSubscriptionItemMutation();
  const { removeSubscriptionItems, isLoading: isUpdating } = useCancelPaddleSubscription();

  // Get all active plans
  const {
    data: activePlans,
    isLoading: isLoadingActivePlans,
    refetch: refetchActivePlans,
  } = useGetPaddleActivePlansQuery(
    { customer_id: subscriptions?.results[0]?.customer_id },
    { skip: !subscriptions?.results[0]?.customer_id }
  );

  // Utility functions
  const hasAddons = (subscription) => {
    return subscription.items.length > 1;
  };

  // Action handlers
  const handleActionComplete = async (message) => {
    try {
      setIsLoading(true);
      // We wait here in case there is a delay on updating via paddle.
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await Promise.all([refetch(), refetchActivePlans()]);
      messageRef.current = { message, type: 'success' };
    } finally {
      setIsChangingPlan(false);
      setIsLoading(false);
      setHasRecentChanges(true);
    }
  };

  const handleChangePlan = async (subscription) => {
    setIsLoading(true);
    setSelectedSubscription(subscription);
    setIsChangingPlan(true);
  };

  const handleRemoveItem = (subscription, item) => {
    setSelectedSubscription(subscription);
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubscription(null);
    setSelectedItem(null);
  };

  const handleAddonChange = async (subscriptionId, quantity) => {
    await refetch();
  };

  // New handlers to encapsulate state changes
  const handleBackToSubscription = () => {
    setIsChangingPlan(false);
    setIsLoading(false);
  };

  const handleSubscriptionChange = () => {
    setHasRecentChanges(true);
  };

  const handlePlanChangeError = () => {
    setIsLoading(false);
  };

  return {
    // State (read-only)
    selectedSubscription,
    selectedItem,
    isModalOpen,
    isChangingPlan,
    isLoading,
    hasRecentChanges,
    messageRef,

    // Data
    subscriptions,
    activePlans,
    isLoadingSubscriptions,
    isLoadingActivePlans,
    isUpdating,
    error,

    // Action handlers (instead of direct state setters)
    handleBackToSubscription,
    handleSubscriptionChange,
    handlePlanChangeError,
    handleChangePlan,
    handleRemoveItem,
    handleCloseModal,
    handleActionComplete,
    handleAddonChange,
    changeSubscriptionItem,

    // Utilities
    hasAddons,
    formatPrice,
    refetch,
  };
}
