import { Link } from 'react-router-dom';
import { z } from 'zod';

import { GA4_BIGQUERY_LINK_LIST } from 'routes/constants';
/**
 * DEFINTIION: Schema Form
 * For reasons unknown to me we have to define the schema in reverse order.
 */

export const sourceGA4BigQueryPart1SchemaForm = z
  .object({
    // What property IDs are we processing?
    gcloud_project_id: z.string(),
    property_ids: z.array(z.string()).min(1, 'At least one property ID is required'),
    timezone: z.string().optional(),
    run_for_date_period: z.boolean().optional().default(true),
    run_for_date_period_start_date: z
      .string()
      .optional()
      .nullable()
      .default(() => {
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 8); // -8 because we want 7 days before yesterday
        return weekAgo.toISOString().split('T')[0];
      }),
    run_for_date_period_end_date: z
      .string()
      .optional()
      .nullable()
      .default(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday.toISOString().split('T')[0];
      }),
  })
  .refine(
    (data) => {
      if (data.run_for_date_period) {
        return data.run_for_date_period_start_date !== null;
      }
      return true;
    },
    {
      message: 'Start date is required when run_for_date_period is true',
      path: ['run_for_date_period_start_date'],
    }
  )
  .refine(
    (data) => {
      if (data.run_for_date_period) {
        return data.run_for_date_period_end_date !== null;
      }
      return true;
    },
    {
      message: 'End date is required when run_for_date_period is true',
      path: ['run_for_date_period_end_date'],
    }
  );

export const fieldInfoSourceGA4BigQueryPart1 = {
  id: { name: 'ID' },
  gcloud_project_id: {
    name: 'GCloud Project ID',
    description: 'The GCloud project ID where the raw GA4 data is stored.',
  },
  property_ids: {
    name: 'Property IDs',
    description: (
      <p>
        Can&apos;t find the property ID you&apos;re looking for? We only show those which are
        already exporting to BigQuery.
        <br /> You can find a list of your exporting GA4 properties here -{' '}
        <Link to={GA4_BIGQUERY_LINK_LIST} target="_blank" rel="noopener noreferrer">
          View BigQuery Links.
        </Link>
      </p>
    ),
  },
  timezone: {
    name: 'Timezone',
    description: 'What timezone is your GA4 data in?',
  },
  run_for_date_period: {
    name: 'Run for period',
    description: (
      <p>
        When first setting up, you will want to run for a small period i.e. several days or a week.{' '}
        <br />
        This allows you to check the numbers are ok and that all your additional data is pulling
        through.
      </p>
    ),
  },
  item_properties_to_unnest: {
    name: 'Item Properties to Unnest',
    description: 'Which extra pieces of information do we want from our items?',
  },
};
