import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormNavigation from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryTitle from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryTitle';
import SourceGA4BigQueryFormChannelGroupings from 'pages/models/source_ga4_bigquery/FormPageChannelGrouping/components/SourceGA4BigQueryFormChannelGroupings';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * SourceGA4BigQueryFormPageChannelGroupings component for the Channel Groupings form page
 */
function SourceGA4BigQueryFormPageChannelGroupings() {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const pageTitle = isUpdate
    ? 'Update GA4 Pipeline Channel Groupings'
    : 'Create GA4 Pipeline Channel Groupings';

  // Mock mutation for testing
  const mockUpdateChannelGroupingsMutation = () => [
    async ({ id, sql }) => {
      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Simulate success
      return { success: true };
    },
  ];

  const handleSuccess = () => {
    // console.log('Form submitted successfully');
    // Handle what happens after successful form submission
    // Redirect or show a success message
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SourceGA4BigQueryTitle
            title={pageTitle}
            pipelineId={pipelineId}
            sourceGA4BigQueryID={id}
            showActions={isUpdate}
          />
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="channel_groupings" />
            <div className="flex-grow mt-5">
              <SourceGA4BigQueryFormChannelGroupings
                pipelineId={pipelineId}
                id={id}
                updateMutation={mockUpdateChannelGroupingsMutation}
                onSuccess={handleSuccess}
              />
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
}

export default SourceGA4BigQueryFormPageChannelGroupings;
