import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { usePaddlePrices } from 'hooks/paddle/usePaddlePrices';
import { setSubscriptionComplete } from 'services/pricing/pricingSlice';
import { useGetCurrentUserQuery } from 'services/rtkApi';

/**
 * Custom hook for managing pricing table logic
 * @param {Object} props
 * @param {Array} props.initialTiers - Initial pricing tiers
 * @param {Function} props.onCheckoutComplete - Callback when checkout completes
 * @param {Function} props.onCheckoutClose - Callback when checkout closes
 * @param {Function} props.onCheckoutError - Callback when checkout errors
 */
export const usePricingTable = ({ initialTiers }) => {
  const dispatch = useDispatch();

  // Get current user data
  const { data: currentUser, error: userError, isLoading: userLoading } = useGetCurrentUserQuery();

  // Set the logic for handling paddle events
  const handlePaddleEvent = (data) => {
    if (data.name === 'checkout.closed' && data.data?.status === 'completed') {
      dispatch(setSubscriptionComplete());
    }
  };

  const { tiers, billingCycle, setBillingCycle, error, isLoading, paddle } = usePaddlePrices(
    initialTiers,
    handlePaddleEvent
  );

  // Local state for tiers
  const [localTiers, setLocalTiers] = useState([]);

  // Effect to update localTiers when tiers change
  // Effect to update localTiers when tiers change
  useEffect(() => {
    if (tiers) {
      setLocalTiers(tiers);
    }
  }, [tiers]);

  // Open paddle checkout with the correct plan.
  const handleCheckout = useCallback(
    (tier, billingCycle) => {
      if (!paddle || !currentUser?.account) {
        console.error('Paddle not initialized or account missing');
        return;
      }

      paddle.Checkout.open({
        items: [
          {
            priceId: tier.priceId[billingCycle],
            quantity: 1,
          },
        ],
        customer: {
          email: currentUser?.email,
        },
        customData: {
          account_id: currentUser.account,
        },
      });
    },
    [paddle, currentUser]
  );

  // Utility functions
  const utils = {
    getBillingCycleText: (billingCycle, priceDisplay) => {
      if (priceDisplay === 'Contact Us' || priceDisplay === 'Price unavailable') return '';
      return billingCycle === 'month' ? '/month' : '/year';
    },

    getTextSizeClass: (priceDisplay, isError) => {
      if (priceDisplay === 'Contact Us' || isError) return 'text-2xl';
      return 'text-4xl';
    },

    classNames: (...classes) => {
      return classes.filter(Boolean).join(' ');
    },
  };

  return {
    // State
    localTiers,
    billingCycle,
    currentUser,

    // Loading/Error states
    isLoading,
    userLoading,
    error,
    userError,

    // Actions
    setBillingCycle,
    handleCheckout,

    // Utility functions
    utils,
  };
};
