import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import FormPropertyList from 'components/Forms/FormPropertyList/FormPropertyList';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useConvertCustomPropertiesValuesToString from 'pages/models/source_ga4_bigquery/_hooks/useConvertCustomPropertiesValuesToString';
import useGA4TableSchema from 'pages/models/source_ga4_bigquery/_hooks/useGA4TableSchema';
import { useGetDbtProjectFileQuery } from 'services/rtkApi';

import useGA4SessionPropertyTemplates, {
  emptySessionTemplateBool,
  emptySessionTemplateStringNumber,
} from '../hooks/useGA4SessionPropertyTemplates';
import {
  fieldInfoSourceGA4DBTSessionProperties,
  sourceGA4BigQuerySessionPropertiesBoolsSchemaForm,
  sourceGA4BigQuerySessionPropertiesNumberSchemaForm,
} from '../schema/sourceGA4DBTSessionPropertiesSchema';

import SourceGA4BigQueryFormSingleSessionProperty from './SourceGA4BigQueryFormSingleSessionProperty';

// Define a lookup constant for schemas
const SCHEMA_LOOKUP = {
  // We default to boolean because it hides nothing.
  default: {
    schema: sourceGA4BigQuerySessionPropertiesBoolsSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTSessionProperties,
  },
  string_number: {
    schema: sourceGA4BigQuerySessionPropertiesNumberSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTSessionProperties,
  },
  bool: {
    schema: sourceGA4BigQuerySessionPropertiesBoolsSchemaForm,
    fieldInfo: fieldInfoSourceGA4DBTSessionProperties,
  },
};

/**
 * SourceGA4BigQueryFormAllSessionProperties component for managing all session properties.
 */
const SourceGA4BigQueryFormAllSessionProperties = () => {
  const { id, pipelineId } = useParams();

  // Go and get table schema for the form.
  const {
    pipelineData,
    tableSchema,
    isLoading: isLoadingTableSchema,
  } = useGA4TableSchema(pipelineId);

  // Get the form data
  const { data: formData, isLoading: isLoadingForm, error } = useGetDbtProjectFileQuery(id);

  // Extract conversion_events and default to an empty array
  const raw_session_properties = formData?.derived_session_properties || [];

  // Because we don't know if we're expecting a string or number, the validation seems to struggle with
  // it. So we convert all to strings. Then when we submit intelligently convert back.
  const session_properties = useConvertCustomPropertiesValuesToString(raw_session_properties);

  /**
   * Get actions and state with the actions.
   */
  const { emptyStateActions, handleAddSessionProperty, handleDeleteSessionProperty, isUpdating } =
    useGA4SessionPropertyTemplates(
      id,
      session_properties,
      formData?.derived_session_properties || []
    );

  // Update items to include the schema_template

  const items = useMemo(() => {
    return session_properties.map((prop, index) => ({
      id: index,
      name: prop.name,
      index,
      schema_template: prop.schema_template || 'default',
    }));
  }, [session_properties]);

  const bottomActions = [
    {
      text: 'Add session property (string/number)',
      description: "Add a session property like 'the last page visited in a session'",
      action: () => handleAddSessionProperty(emptySessionTemplateStringNumber),
    },
    {
      text: 'Add session property (true/false)',
      description: "Add a session property like 'was the last page in a session blog/my-article'",
      action: () => handleAddSessionProperty(emptySessionTemplateBool),
    },
  ];

  /**
   * Define schema options
   */

  if (isLoadingForm || isUpdating) return <LoadingSpinner text="Loading session properties..." />;
  if (error) return <p>Error loading data</p>;

  return (
    <FormPropertyList
      items={items}
      emptyStateTitle="Add session property from template"
      emptyStateDescription="Add a session property to get started."
      emptyStateActions={emptyStateActions}
      emptyProjectText="Add custom session property"
      onDeleteItem={handleDeleteSessionProperty}
      bottomActions={bottomActions}
      isLoading={isUpdating}
    >
      {(selectedItem) => {
        const { schema, fieldInfo } =
          SCHEMA_LOOKUP[selectedItem.schema_template] || SCHEMA_LOOKUP.default;

        return (
          <SourceGA4BigQueryFormSingleSessionProperty
            id={id}
            pipelineId={pipelineId}
            pipelineData={pipelineData}
            tableSchema={tableSchema}
            isLoadingTableSchema={isLoadingTableSchema}
            selectedPropertyIndex={selectedItem.index}
            allProperties={session_properties}
            schema={schema}
            fieldInfo={fieldInfo}
          />
        );
      }}
    </FormPropertyList>
  );
};

export default SourceGA4BigQueryFormAllSessionProperties;
