import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoSourceGA4DBTAdvancedBQSchema,
  sourceGA4BigQueryAdvancedBQSchemaForm,
} from 'pages/models/source_ga4_bigquery/FormPageAdvancedBQ/schema/sourceGA4DBTAdvancedBQSchema';
import {
  useAddSourceGA4BigQueryMutation,
  useGetDbtProjectFileQuery,
  usePartialUpdateDbtProjectFileMutation,
} from 'services/rtkApi';

const SourceGA4BigQueryFormAdvancedBQ = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const navigate = useNavigate();
  const is_update = Boolean(id);
  const form_type = is_update ? 'update' : 'create';

  const mutation_hook = is_update
    ? usePartialUpdateDbtProjectFileMutation
    : useAddSourceGA4BigQueryMutation;
  const queryHook = is_update ? useGetDbtProjectFileQuery : undefined;

  const pre_submit = React.useCallback(
    (form_data) => {
      return {
        ...form_data,
      };
    },
    [pipelineId]
  );

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${form_type}`}
      formType={form_type}
      mutationHook={mutation_hook}
      queryHook={queryHook}
      schema={sourceGA4BigQueryAdvancedBQSchemaForm}
      fieldInfo={fieldInfoSourceGA4DBTAdvancedBQSchema}
      warnOnUnsavedChanges
      preSubmit={pre_submit}
    />
  );
};

export default SourceGA4BigQueryFormAdvancedBQ;
