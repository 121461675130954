import { useGetLatestPipelineRunQuery, useGetSourceGA4BigQueryByIdQuery } from 'services/rtkApi';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Tooltip from '../Tooltip/Tooltip';

/**
 * Calculates the current progress of the GA4 setup pipeline
 */
const calculateCurrentProgress = ({ buildStatus, isRunning }) => {
  const setupSteps = [
    { name: 'Raw Data Copied', href: '#' },
    { name: 'Base Table', href: '#' },
    { name: 'Intermediate Tables', href: '#' },
    { name: 'Output Tables', href: '#' },
    { name: 'Complete', href: '#' },
  ];

  const statusToStepName = {
    not_started: 'Not Started',
    raw_data_copied: 'Raw Data Copied',
    base_table_built: 'Base Table',
    intermediate_tables_built: 'Intermediate Tables',
    output_tables_built: 'Output Tables',
    complete: 'Complete',
  };

  const lastCompletedStep = statusToStepName[buildStatus] || 'Not Started';
  const lastCompletedIndex = setupSteps.findIndex((step) => step.name === lastCompletedStep);

  const currentStep =
    buildStatus === 'complete'
      ? 'Complete'
      : lastCompletedIndex < setupSteps.length - 1
      ? setupSteps[lastCompletedIndex + 1].name
      : lastCompletedStep;

  return {
    steps: setupSteps.map((step, index) => ({
      ...step,
      isRunning: buildStatus !== 'complete' && step.name === currentStep && isRunning,
      isSuccess: index <= lastCompletedIndex || buildStatus === 'complete',
    })),
    currentStep,
  };
};

/**
 * A progress indicator component showing current step and navigation dots
 * @param {Object} props
 * @param {string} props.pipelineId - ID of the pipeline
 * @param {string} props.sourceGA4BigQueryID - ID of the GA4 BigQuery source
 * @param {boolean} props.isRunInProgress - Whether the pipeline is currently running
 */
const ProgressBar = ({ pipelineId, sourceGA4BigQueryID, isRunInProgress }) => {
  const { data: latestRun, isLoading: isLoadingRun } = useGetLatestPipelineRunQuery(pipelineId);
  const { data: sourceGA4BigQuery, isLoading: isLoadingSource } =
    useGetSourceGA4BigQueryByIdQuery(sourceGA4BigQueryID);

  if (isLoadingRun || isLoadingSource) {
    return (
      <div className="flex items-center justify-center border border-gray-200 rounded-lg p-4">
        <LoadingSpinner size="4" />
      </div>
    );
  }

  const buildStatus = sourceGA4BigQuery?.current_pipeline_build_status || 'not_started';

  // This is passed in externally to force this to re-render when
  // the run status changes.
  const isRunning = isRunInProgress || false;

  const { steps, currentStep } = calculateCurrentProgress({
    buildStatus,
    isRunning,
  });

  const currentStepIndex = steps.findIndex((step) => step.name === currentStep);

  const getStatus = (index) => {
    if (index === steps.length - 1 && steps[index].isSuccess) {
      return 'complete';
    }

    if (index === currentStepIndex) return 'current';
    if (index < currentStepIndex) return 'complete';
    return 'upcoming';
  };

  const getRunStatus = () => {
    if (!latestRun) return null;
    if (latestRun.is_run_in_progress) return 'Running';
    return latestRun.did_run_succeed ? 'Last run: success' : 'Last run: failed';
  };

  return (
    <nav
      aria-label="Progress"
      className="flex items-center justify-center border border-gray-200 rounded-lg p-4"
    >
      <div className="flex flex-col">
        <span className="text-sm font-medium">Current status</span>
        {getRunStatus() && <span className="text-xs text-gray-500">{getRunStatus()}</span>}
      </div>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step, index) => (
          <li key={step.name}>
            <Tooltip
              content={`${step.name} - ${
                step.isRunning
                  ? 'In Progress'
                  : getStatus(index) === 'complete'
                  ? step.isSuccess
                    ? 'Completed Successfully'
                    : 'Failed'
                  : getStatus(index) === 'current'
                  ? 'Current Step'
                  : ''
              }`}
              position="bottom"
            >
              {step.isRunning ? (
                <LoadingSpinner size="4" className="text-deep-sea-green-600" />
              ) : getStatus(index) === 'complete' ? (
                <a
                  href={step.href}
                  className={`block h-2.5 w-2.5 rounded-full ${
                    step.isSuccess ? 'bg-green-600' : 'bg-red-600'
                  } hover:bg-opacity-75`}
                >
                  <span className="sr-only">{step.name}</span>
                </a>
              ) : getStatus(index) === 'current' ? (
                <a
                  href={step.href}
                  aria-current="step"
                  className="relative flex items-center justify-center"
                >
                  <span aria-hidden="true" className="absolute flex h-5 w-5 p-px">
                    <span className="h-full w-full rounded-full bg-indigo-200" />
                  </span>
                  <span
                    aria-hidden="true"
                    className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              ) : (
                <a
                  href={step.href}
                  className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                >
                  <span className="sr-only">{step.name}</span>
                </a>
              )}
            </Tooltip>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ProgressBar;

// Usage example:
// const steps = [
//   { name: 'Details', href: '#', isRunning: false, isSuccess: true },
//   { name: 'Address', href: '#', isRunning: true, isSuccess: false },
//   { name: 'Payment', href: '#', isRunning: false, isSuccess: true },
// ]
// <ProgressBar steps={steps} currentStep="Address" /> // Shows spinner for "Address"
