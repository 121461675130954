import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetBigQueryDatasetsQuery, useGetGoogleBigQueryProjectsQuery } from 'services/rtkApi';

export const useBigQueryProjectAndDataset = (queryParams) => {
  const googleAPIAuthStatus = useSelector((state) => state.auth.googleAPIAuthStatus);
  const isAuthorized = googleAPIAuthStatus;

  const [foreignKeyOptions, setForeignKeyOptions] = useState([]);

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    error: projectsError,
  } = useGetGoogleBigQueryProjectsQuery(undefined, {
    skip: !isAuthorized,
  });

  const {
    data: datasetsData,
    isLoading: isLoadingDatasets,
    isFetching: isFetchingDatasets,
    error: datasetsError,
    refetch: refetchDatasets,
  } = useGetBigQueryDatasetsQuery(queryParams, {
    skip: !isAuthorized || !queryParams.project_id,
  });

  // We only refetch the datasets if the project_id is set.
  // This prevents from making requests which will fail.
  const safeRefetchDatasets = useCallback(() => {
    if (queryParams.project_id) {
      refetchDatasets();
    }
  }, [queryParams.project_id]);

  // Effect to trigger dataset refetch when project_id changes
  useEffect(() => {
    safeRefetchDatasets();
  }, [queryParams.project_id, safeRefetchDatasets]);

  /**
   * Set foreign key options if our data ever changes.
   */
  useEffect(() => {
    const newOptions = [
      {
        key: ['gcloud_project_id'],
        options: projectsData?.map((project) => ({ value: project.id, label: project.name })) || [],
      },
      {
        // We filter out the pl_raw datasets because they aren't valid targets
        // we automatically create them for raw data.
        key: ['bq_output_dataset'],
        options:
          datasetsData
            ?.filter((dataset) => !dataset.id.includes('pl_raw'))
            .map((dataset) => ({
              value: dataset.id,
              label: dataset.name,
              location: dataset.location,
            })) || [],
      },
    ];

    if (!isEqual(newOptions, foreignKeyOptions)) {
      setForeignKeyOptions(newOptions);
    }
  }, [projectsData, datasetsData, foreignKeyOptions]);

  /**
   * Set loading foreign keys if any of our queries are loading.
   */
  const loadingForeignKeys = [
    ...(isLoadingDatasets || isFetchingDatasets ? [['bq_output_dataset']] : []),
    ...(isLoadingProjects ? [['gcloud_project_id']] : []),
  ];

  return {
    foreignKeyOptions,
    loadingForeignKeys,
    refetchDatasets: safeRefetchDatasets,
    isAuthorized,
    errors: {
      projects: projectsError,
      datasets: datasetsError,
    },
  };
};
