import React from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable'; // Adjust the import path
import { ACCOUNT_UPDATE_FORM } from 'routes/constants';
import { accountSchemaList } from 'schemas'; // Adjust the import path
import { useGetAccountsQuery } from 'services/rtkApi'; // Adjust the import path

const AccountTable = () => {
  const { data, error, isLoading } = useGetAccountsQuery();

  // Optional: Validate or parse data with Zod (if needed)
  const validatedData = data ? accountSchemaList.parse(data.results[0]) : [];

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Contact Info', accessor: 'contact_info' },
      // Add more columns as needed
    ],
    []
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading accounts</div>;

  return (
    <FlexListTable
      columns={columns}
      data={data.results || []}
      hiddenColumnIds={['id']}
      linkPattern={ACCOUNT_UPDATE_FORM}
    />
  );
};

export default AccountTable;
