/**
 * Main subscription management page that conditionally renders either
 * ChooseYourPlan or ManageYourPlan based on subscription status
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { showToastLink } from 'components/ToasterLink/ToasterLink';
import {
  clearOnboardingPostSubscription,
  clearSubscriptionComplete,
} from 'services/pricing/pricingSlice';
import { useGetCurrentUserQuery, useGetPaddleSubscriptionsQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

import ChooseYourPlan from './components/ChooseYourPlan/ChooseYourPlan';
import ManageYourPlan from './components/ManageYourPlan/ManageYourPlan';

export default function PlanManagementPage() {
  const dispatch = useDispatch();
  const { data: subscriptions, isLoading, refetch } = useGetPaddleSubscriptionsQuery();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [isForceChoosePlan, setIsForceChoosePlan] = useState(false);
  const is_checkout_completed = useSelector((state) => state.pricing.is_checkout_completed);
  const is_onboarding_post_subscription = useSelector(
    (state) => state.pricing.is_onboarding_post_subscription
  );

  // Watch for checkout completion and refetch data with delay
  useEffect(() => {
    if (is_checkout_completed) {
      const timer = setTimeout(() => {
        refetch();
        dispatch(clearSubscriptionComplete());
      }, 1500);

      return () => clearTimeout(timer); // Cleanup timer if component unmounts
    }
  }, [is_checkout_completed, refetch, dispatch]);

  // Handle onboarding toast
  useEffect(() => {
    if (is_onboarding_post_subscription) {
      showToastLink({
        message: 'Thanks for signing up! Click here to continue setting up your account',
        url: '/',
        toastOptions: { autoClose: 10000 }, // Show for 10 seconds
      });
      dispatch(clearOnboardingPostSubscription());
    }
  }, [is_onboarding_post_subscription, dispatch]);

  const hasActiveSubscription = subscriptions?.results?.length > 0;
  const hasCancelledSubscription = subscriptions?.results?.some((sub) => sub.status === 'canceled');

  const showChoosePlan = isForceChoosePlan || !hasActiveSubscription;

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="flex justify-between items-center w-full">
            <div>
              <h1 className="text-xl font-semibold leading-7 text-gray-900">
                {showChoosePlan ? 'Choose Your Plan' : 'Manage Your Plan'}
              </h1>
            </div>
            <Button onClick={() => setIsForceChoosePlan(!isForceChoosePlan)} variant="secondary">
              {hasCancelledSubscription
                ? 'Reactivate Plan'
                : `Switch to ${showChoosePlan ? 'Manage Plan' : 'Choose Plan'}`}
            </Button>
            {/* {!showChoosePlan &&
              (currentUser?.is_superuser || hasCancelledSubscription) &&
              hasActiveSubscription && (

              )} */}
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip hasFlex={false}>
          {isLoading ? (
            <div>Loading...</div>
          ) : showChoosePlan ? (
            <ChooseYourPlan />
          ) : (
            <ManageYourPlan />
          )}
        </PageLayoutInnerStrip>
      </div>
    </PageLayout>
  );
}
