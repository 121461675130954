import PropTypes from 'prop-types';
import Select from 'react-select';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { unformattedToTitleCase } from 'utils';
import { parseDescriptionForDrawerButtonOrLink } from 'utils/componentUtils';

const FormSelectNonHookForm = ({
  fieldKey,
  label,
  value,
  onChange,
  options,
  showLabel = true,
  isMulti = false,
  description,
  isLoading,
  disabled = false,
  className,
}) => {
  return (
    <div className={`sm:col-span-4 ${className}`}>
      {showLabel && (
        <div>
          <div className="flex items-center">
            <label htmlFor={fieldKey} className="block text-sm font-medium leading-6 text-gray-900">
              {unformattedToTitleCase(label)}
            </label>
            {isLoading && (
              <div className="ml-2">
                <LoadingSpinner />
              </div>
            )}
          </div>
          <p className="mt-1 pb-0 text-sm text-gray-500">
            {parseDescriptionForDrawerButtonOrLink(description)}
          </p>
        </div>
      )}
      <div className="mt-2 control">
        <Select
          options={options || []}
          isMulti={isMulti}
          isDisabled={disabled}
          value={
            isMulti
              ? (options || []).filter((opt) =>
                  (Array.isArray(value) ? value : []).includes(opt.value)
                )
              : (options || []).find((opt) => opt.value === value) || null
          }
          onChange={(selectedOption) => {
            if (isMulti) {
              onChange(selectedOption ? selectedOption.map((opt) => opt.value) : []);
            } else {
              onChange(selectedOption ? selectedOption.value : null);
            }
          }}
          className={`w-full ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        />
      </div>
    </div>
  );
};

FormSelectNonHookForm.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  showLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

FormSelectNonHookForm.defaultProps = {
  showLabel: true,
  isMulti: false,
  description: '',
  isLoading: false,
  disabled: false,
  className: '',
  value: null,
};

export default FormSelectNonHookForm;
