import React, { useState } from 'react';

import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import FlexListTable from 'components/FlexListTable/FlexListTable'; // Adjust the import path
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ModalAction from 'components/ModalAction/ModalAction';
import TextBubble from 'components/TextBubble/TextBubble'; // Add this import
import { usePipelinePolling } from 'hooks/usePipelinePolling';
import {
  SOURCE_GA4_BIGQUERY_CREATE_FORM,
  SOURCE_GA4_BIGQUERY_UPDATE_FORM,
  SOURCE_GA4_BIGQUERY_UPGRADE_FORM,
} from 'routes/constants';
import {
  useDeleteSourceGA4BigQueryMutation,
  useGetLatestPipelineRunQuery,
  useGetSourceGA4BigQueriesQuery,
} from 'services/rtkApi'; // Adjust the import path

const SourceGA4BigQueryTable = ({ pipelineId }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSourceId, setSelectedSourceId] = useState(null);
  const [deleteSource] = useDeleteSourceGA4BigQueryMutation();

  // Get the latest pipeline run which we can use to control polling.
  const { data: latestPipelineRun } = useGetLatestPipelineRunQuery(pipelineId);

  // Get the source GA4 BigQuery data.
  const { data, error, isLoading, refetch } = useGetSourceGA4BigQueriesQuery(
    pipelineId ? { pipeline: pipelineId } : {}
  );

  // Check if latest run is in progress
  const isRunInProgress = latestPipelineRun?.is_run_in_progress || false;

  // Use the polling hook
  usePipelinePolling({
    refetchFn: refetch,
    isRunInProgress,
  });

  // Optional: Validate or parse data with Zod (if needed)
  // if (data && data.results.length > 0) {
  //   console.log('data', data.results[0]);
  // }
  // const validatedData =
  //   data && data.results.length > 0 ? sourceGA4BigQuerySchemaList.parse(data.results[0]) : [];

  const getStatusColor = (status) => {
    switch (status) {
      case 'complete':
        return 'green';
      case 'not_started':
        return 'grey';
      case 'raw_data_copied':
      case 'parameters_extracted':
        return 'green'; // or another color if you prefer
      default:
        return 'grey';
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      {
        Header: 'Property IDs',
        accessor: 'property_ids',
        Cell: ({ value }) => (
          <div className="flex flex-wrap gap-1">
            {value.map((id, index) => (
              <TextBubble key={index} color="grey" text={id} />
            ))}
          </div>
        ),
      },
      {
        Header: 'Build Status',
        accessor: 'current_pipeline_build_status',
        Cell: ({ value }) => (
          <TextBubble color={getStatusColor(value)} text={value.replace(/_/g, ' ')} />
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    try {
      await deleteSource(selectedSourceId).unwrap();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete source:', error);
    }
  };

  const linkButtons = [
    {
      label: 'Edit GA4',
      link: SOURCE_GA4_BIGQUERY_UPDATE_FORM.replace(':pipelineId', pipelineId),
    },
    {
      label: 'Upgrade GA4 Version',
      link: SOURCE_GA4_BIGQUERY_UPGRADE_FORM.replace(':pipelineId', pipelineId),
    },
    {
      label: 'Delete',
      onClick: (row) => {
        setSelectedSourceId(row.id);
        setIsDeleteModalOpen(true);
      },
    },
  ];

  if (isLoading) return <LoadingSpinner text="Loading GA4 sources..." />;
  if (error) return <div>Error loading GA4 pipelines</div>;

  // When we have no data we want to:
  let outputTable;

  if (data.results.length > 0) {
    outputTable = (
      <FlexListTable
        columns={columns}
        data={data.results || []}
        hiddenColumnIds={['id']}
        linkButtons={linkButtons}
      />
    );
  } else {
    outputTable = (
      <CommonActionSingle
        title="You've got no data sources. Let's add one!"
        buttonName="Add data"
        href={SOURCE_GA4_BIGQUERY_CREATE_FORM.replace(':pipelineId', pipelineId)}
        description={
          <div>
            <p>Add a GA4 reporting set to get started.</p>
            <p>This will typically be all the GA4 sources associated with one website. </p>
          </div>
        }
      />
    );
  }

  return (
    <>
      {outputTable}
      <ModalAction
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confirm Deletion"
        description="Are you sure you want to delete this GA4 source? This action cannot be undone."
        actionType="delete"
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            variant: 'primary',
          },
          {
            text: 'Delete',
            onClick: handleDelete,
            variant: 'danger',
          },
        ]}
      />
    </>
  );
};

export default SourceGA4BigQueryTable;
