import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import { PricingTableDisplay } from './PricingTableDisplay';
import { usePricingTableUpgrade } from './usePricingTableUpgrade';
/**
 * PricingTableUpgrade - Pricing table variant for existing subscribers
 */
export default function PricingTableUpgrade({
  initialTiers,
  sections,
  existingSubscription,
  changeSubscriptionItem,
}) {
  const {
    localTiers,
    billingCycle,
    isChanging,
    isPricesLoading,
    error,
    setBillingCycle,
    handlePlanChange,
    getButtonText,
    utils,
  } = usePricingTableUpgrade({
    initialTiers,
    existingSubscription,
    changeSubscriptionItem,
  });

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isChanging) {
    return (
      <div className="flex justify-center py-8">
        <LoadingSpinner text="Updating your subscription..." />
      </div>
    );
  }

  return (
    <PricingTableDisplay
      localTiers={localTiers}
      billingCycle={billingCycle}
      setBillingCycle={setBillingCycle}
      sections={sections}
      isLoading={isPricesLoading}
      handleAction={handlePlanChange}
      getButtonText={getButtonText}
      utils={utils}
      isUpgrade
    />
  );
}
