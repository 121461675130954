/**
 * Initial plan selection for new subscribers
 */
import PricingTable from '../PricingTables/PricingTable';
import {
  COUNTRIES,
  FEATURE_SECTIONS,
  PRICING_TIERS_DEV,
  PRICING_TIERS_PROD,
} from '../pricingV1Constants';

export default function ChooseYourPlan() {
  const pricingTiers = window.IS_DEV ? PRICING_TIERS_DEV : PRICING_TIERS_PROD;

  return (
    <PricingTable initialTiers={pricingTiers} sections={FEATURE_SECTIONS} countries={COUNTRIES} />
  );
}
