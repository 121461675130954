import { z } from 'zod';

export const conditionSchema = z.object({
  col: z.string(),
  operator: z.enum(['=', '>', '<', '>=', '<=', 'contains', 'regex_contains']).describe({
    '=': 'Equals',
    '>': 'Greater than',
    '<': 'Less than',
    '>=': 'Greater than or equal to',
    '<=': 'Less than or equal to',
    contains: 'Contains',
    regex_contains: 'Regex contains',
  }),
  value: z.union([z.string(), z.number()]),
});

export const formLogicBlockSchema = z
  .object({
    andOr: z.enum(['and', 'or']).nullable().optional(),
    conditions: z.array(conditionSchema).nullable().optional(),
  })
  .refine(
    (data) => {
      return !data.conditions?.length || !!data.andOr;
    },
    {
      message: 'andOr must have a value when conditions are present',
      path: ['andOr'],
    }
  );

/**
 * Value schema for the property conditions for session and conversions.
 */
export const valueSchema = z
  .object({
    col: z.string().nullable().optional(),
    col_type: z.string().optional().nullable().describe({ hidden: true }),
    agg: z.enum(['sum', 'count', 'max', 'last_value', 'first_value']),
    on_condition_true: z.union([z.string(), z.number()]).optional(),
  })
  .refine(
    (data) => {
      return (data.col && !data.on_condition_true) || (!data.col && data.on_condition_true);
    },
    {
      message: 'You need to either pick a column or a static value.',
      path: ['on_condition_true'],
    }
  );

/**
 * Session Properties schema segments.
 */
export const propertyConditionBoolsSchema = z.array(
  z.object({
    conditionBlock: formLogicBlockSchema.optional(),
    value: valueSchema,
    compare: z
      .object({
        operator: z.enum(['=', '!=', '>', '<', '>=', '<=']).nullable().optional().describe({
          '=': 'Equals',
          '!=': 'Not equals',
          '>': 'Greater than',
          '<': 'Less than',
          '>=': 'Greater than or equal to',
          '<=': 'Less than or equal to',
        }),
        value: z.string().nullable().optional(),
      })
      .refine(
        (data) => {
          if (!data) return true;
          return (data.operator && data.value) || (!data.operator && !data.value);
        },
        {
          message: 'Both operator and value must be provided together',
          path: ['operator'],
        }
      )
      .optional(),
  })
);

export const propertyConditionStringNumberSchema = z
  .array(
    z.object({
      conditionBlock: formLogicBlockSchema.optional(),
      value: valueSchema,
    })
  )
  .min(1)
  .max(1);
