import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useGetAllGA4PropertySummariesQuery,
  useGetBigQueryDatasetsQuery,
  useGetGoogleBigQueryProjectsQuery,
} from 'services/rtkApi';

export const useGA4BigQueryFormData2 = (queryParams) => {
  const googleAPIAuthStatus = useSelector((state) => state.auth.googleAPIAuthStatus);
  const isAuthorized = googleAPIAuthStatus;
  console.log('googleAPIAuthStatus', googleAPIAuthStatus);

  console.log('isAuthorized', isAuthorized);

  const [foreignKeyOptions, setForeignKeyOptions] = useState([]);
  const [isManuallyRefetching, setIsManuallyRefetching] = useState(false);

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    error: projectsError,
  } = useGetGoogleBigQueryProjectsQuery(undefined, {
    skip: !isAuthorized,
  });

  const {
    data: datasetsData,
    isLoading: isLoadingDatasets,
    isFetching: isFetchingDatasets,
    error: datasetsError,
    refetch: refetchDatasets,
  } = useGetBigQueryDatasetsQuery(
    { ...queryParams, ignore_ga4_raw_exports: false },
    {
      skip: !isAuthorized || !queryParams.project_id,
    }
  );

  const {
    data: propertiesData,
    isLoading: isLoadingProperties,
    isFetching: isFetchingProperties,
    error: propertiesError,
  } = useGetAllGA4PropertySummariesQuery(
    { projectId: queryParams.project_id },
    { skip: !isAuthorized || !queryParams.project_id }
  );

  // Modify the refetch function to track loading state
  const safeRefetchDatasets = useCallback(() => {
    // Only attempt refetch if we're authorized and have a project_id
    if (queryParams.project_id && isAuthorized) {
      setIsManuallyRefetching(true);
      refetchDatasets().finally(() => {
        setIsManuallyRefetching(false);
      });
    }
  }, [queryParams.project_id, refetchDatasets, isAuthorized]);

  // Effect to trigger dataset refetch when project_id changes
  useEffect(() => {
    safeRefetchDatasets();
  }, [queryParams.project_id, safeRefetchDatasets]);

  /**
   * Set foreign key options if our data ever changes.
   */
  useEffect(() => {
    // Get property IDs from dataset names
    const propertyIdsFromDatasets =
      datasetsData
        ?.filter((dataset) => /analytics_\d{7,12}$/.test(dataset.name))
        .map((dataset) => dataset.name.match(/\d{7,12}/)[0]) || [];

    const newOptions = [
      {
        key: ['gcloud_project_id'],
        options: projectsData?.map((project) => ({ value: project.id, label: project.name })) || [],
      },
      {
        key: ['bq_output_dataset'],
        options:
          datasetsData?.map((dataset) => ({
            value: dataset.id,
            label: dataset.name,
            location: dataset.location,
          })) || [],
      },
      {
        key: ['property_ids'],
        options:
          propertyIdsFromDatasets.map((datasetId) => {
            // Find matching property from propertiesData if it exists
            const matchingProperty = propertiesData?.find((p) => p.property_id === datasetId);
            return {
              value: datasetId,
              label: matchingProperty ? matchingProperty.display_name : `Property ${datasetId}`,
            };
          }) || [],
      },
    ];

    if (!isEqual(newOptions, foreignKeyOptions)) {
      setForeignKeyOptions(newOptions);
    }
  }, [projectsData, datasetsData, propertiesData, foreignKeyOptions]);

  /**
   * Set loading foreign keys if any of our queries are loading or fetching
   */
  const isDatasetLoading = isLoadingDatasets || isFetchingDatasets || isManuallyRefetching;
  const loadingForeignKeys = [
    ...(isDatasetLoading ? [['bq_output_dataset']] : []),
    ...(isLoadingProjects ? [['gcloud_project_id']] : []),
    ...(isLoadingProperties || isFetchingProperties ? [['property_ids']] : []),
  ];

  return {
    foreignKeyOptions,
    loadingForeignKeys,
    refetchDatasets: safeRefetchDatasets,
    isAuthorized,
    errors: {
      projects: projectsError,
      datasets: datasetsError,
      properties: propertiesError,
    },
    datasets: datasetsData || [],
  };
};
