export const PRICING_TIERS_DEV = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '#',
    priceDisplay: {
      month: '£150',
      year: '£1500',
    },
    mostPopular: false,
    priceId: {
      month: 'pri_01j5z8vtdv0mpa3qzhyrtyywcv',
      year: 'pri_01j5z8xsmnsa1mdchhd7be3q0w',
    },
    addons: [
      {
        name: 'Additional Property',
        description: 'Add extra properties to your plan',
        priceId: {
          month: 'pri_01jbcrynhv7p2bav06rkwtqc5g',
          year: 'pri_01jcycnf36ae776cfs2ens6110',
        },
      },
    ],
  },
  {
    name: 'Core',
    id: 'tier-core',
    href: '#',
    priceDisplay: {
      month: '$29',
      year: '$290',
    },
    mostPopular: true,
    priceId: {
      month: 'pri_01j4ppg56ygqfjpmt880dnksms',
      year: 'pri_01j4ppkrafpv6g4tan5hkqs695',
    },
  },
  {
    name: 'Custom',
    id: 'tier-custom',
    href: '#',
    priceDisplay: {
      month: 'Contact Us',
      year: 'Contact Us',
    },
    mostPopular: false,
  },
];

export const PRICING_TIERS_PROD = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '#',
    priceDisplay: {
      month: '£150',
      year: '£1500',
    },
    mostPopular: false,
    priceId: {
      month: 'pri_01jgc9c0e6679bdbtn5c9kxaqj',
      year: 'pri_01jgc9b0xcf6e0z20e9f9vnn5e',
    },
    addons: [
      {
        name: 'Additional Property',
        description: 'Add extra properties to your plan',
        priceId: {
          month: 'pri_01jgc8qshjfhpr3vcz367d58j5',
          year: 'pri_01jgc8pq0ewj2ewjxkf9128h91',
        },
      },
    ],
  },
  {
    name: 'Core',
    id: 'tier-core',
    href: '#',
    priceDisplay: {
      month: '$29',
      year: '$290',
    },
    mostPopular: true,
    priceId: {
      month: 'pri_01jgc9hqr70eyth5d1r47vmybd',
      year: 'pri_01jgc9g3y1kptaapst07gz9mvk',
    },
  },
  {
    name: 'Custom',
    id: 'tier-custom',
    href: '#',
    priceDisplay: {
      month: 'Contact Us',
      year: 'Contact Us',
    },
    mostPopular: false,
  },
];

// Features
export const FEATURE_SECTIONS = [
  {
    name: 'Usage',
    features: [
      {
        name: 'Users',
        tiers: {
          Basic: 'Unlimited',
          Core: 'Unlimited',
          Custom: 'Unlimited',
        },
      },
      {
        name: 'BigQuery Projects',
        tiers: { Basic: '1', Core: '1', Custom: 'Unlimited' },
      },
      {
        name: 'Max daily event volume',
        tiers: { Basic: '800,000', Core: '2,000,000', Custom: 'Unlimited' },
      },
    ],
  },
  {
    name: 'Features',
    features: [
      {
        name: 'Reporting Templates',
        tiers: { Basic: true, Core: true, Custom: true },
      },
      {
        name: 'External warehouse export',
        tiers: { Basic: false, Core: true, Custom: true },
      },
      {
        name: 'Dedicated Slack Channel',
        tiers: {
          Basic: false,
          Core: true,
          Custom: true,
        },
      },
      {
        name: 'Customer Success Engineer',
        tiers: {
          Basic: false,
          Core: true,
          Custom: true,
        },
      },

      {
        name: 'Setup',
        tiers: { Basic: false, Core: true, Custom: true },
      },
    ],
  },
];

export const COUNTRIES = [
  { code: 'US', name: 'United States' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'CA', name: 'Canada' },
  // Add more countries as needed
];
