const { PRICING_TIERS_DEV, PRICING_TIERS_PROD } = require('./pricingV1Constants');

const pricingTiers = window.IS_DEV ? PRICING_TIERS_DEV : PRICING_TIERS_PROD;

/**
 * Get all addon price IDs from pricing tiers
 */
const getAllAddonPriceIds = () => {
  const addonPriceIds = new Set();

  pricingTiers.forEach((tier) => {
    if (tier.addons) {
      tier.addons.forEach((addon) => {
        if (addon.priceId) {
          addonPriceIds.add(addon.priceId.month);
          addonPriceIds.add(addon.priceId.year);
        }
      });
    }
  });

  return [...addonPriceIds];
};
/**
 * Check if a price ID belongs to an addon
 */

export const isAddonPrice = (priceId) => {
  return getAllAddonPriceIds().includes(priceId);
};
/**
 * Get tier and addon details for a given price ID
 */

export const getTierAndAddonDetails = (priceId) => {
  for (const tier of pricingTiers) {
    // Check if it's a base tier price
    if (tier.priceId?.month === priceId || tier.priceId?.year === priceId) {
      return { tier, isAddon: false };
    }

    // Check addons
    if (tier.addons) {
      const addon = tier.addons.find(
        (addon) => addon.priceId?.month === priceId || addon.priceId?.year === priceId
      );
      if (addon) {
        return { tier, addon, isAddon: true };
      }
    }
  }
  return null;
};

/**
 * Formats a price amount in cents to a localized currency string
 * @param {number} amount - The amount in cents
 * @param {string} currency - The currency code (e.g., 'USD', 'GBP')
 * @returns {string} Formatted price string
 */
export const formatPrice = (amount, currency) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
  }).format(amount / 100);
};
