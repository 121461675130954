// src/store.js
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import authReducer from 'services/auth/authSlice';
import drawerReducer from 'services/drawers/drawerSlice';
import errorReducer from 'services/errors/errorSlice';
import pricingReducer from 'services/pricing/pricingSlice';
import api from 'services/rtkApi';
import rootSaga from 'services/saga';
import sourceGA4BigQueryReducer from 'services/sourceGA4BigQuery/sourceGA4BigQuerySlice';
import userReducer from 'services/user/userSlice';

const sagaMiddleware = createSagaMiddleware();

// Create browser history instance first
const browserHistory = createBrowserHistory();

// Then create history context with the instance
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: browserHistory,
});

// Individual persist configs for each reducer
const authPersistConfig = {
  key: 'auth',
  storage,
  debug: true,
};

const userPersistConfig = {
  key: 'user',
  storage,
  debug: true,
};

const drawerPersistConfig = {
  key: 'drawer',
  storage,
  debug: true,
};

const sourceGA4PersistConfig = {
  key: 'sourceGA4BigQuery',
  storage,
  debug: true,
};

const pricingPersistConfig = {
  key: 'pricing',
  storage,
};

// Add persistReducer to root level while keeping individual persistence
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'error', api.reducerPath], // Don't persist these reducers
};

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    // console.log('logout remove');
    storage.removeItem('persist:root');
    storage.removeItem('persist:auth');
    storage.removeItem('persist:user');
    storage.removeItem('persist:drawer');
    storage.removeItem('persist:sourceGA4BigQuery');
    storage.removeItem('persist:pricing');
    return {};
  }
  return combineReducers({
    router: routerReducer,
    [api.reducerPath]: api.reducer,
    error: errorReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    user: persistReducer(userPersistConfig, userReducer),
    drawer: persistReducer(drawerPersistConfig, drawerReducer),
    sourceGA4BigQuery: persistReducer(sourceGA4PersistConfig, sourceGA4BigQueryReducer),
    pricing: persistReducer(pricingPersistConfig, pricingReducer),
  })(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware, routerMiddleware, sagaMiddleware),
});

// Create history
export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
