import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FlexListTable from 'components/FlexListTable/FlexListTable';
import FormSelectNonHookForm from 'components/FormNonReactHook/FormSelectNonHookForm';

/**
 * Displays field information and example values from the static CSV with field selection
 * @param {Object} props
 * @param {string} props.fieldName - Name of the field to display information for
 */
function FieldExampleValueHelper({ fieldName: initialFieldName }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedField, setSelectedField] = useState(initialFieldName);

  useEffect(() => {
    async function loadData() {
      try {
        const csvData = await import('exampleFieldData.csv');
        const result = Papa.parse(csvData.default, {
          header: true,
          skipEmptyLines: true,
        });
        setData(result.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load field data:', error);
        setData([]);
        setIsLoading(false);
      }
    }
    loadData();
  }, []);

  // Get unique fields for dropdown
  const fieldOptions = Array.isArray(data)
    ? [...new Set(data.map((row) => row.field))].map((field) => ({
        value: field,
        label: field,
      }))
    : [];

  // Filter data for specific field and get unique description
  const fieldInfo = data.find((row) => row.field === selectedField);
  const description = fieldInfo?.description || 'No description available';

  // Filter example values for this field
  const examples = data
    .filter((row) => row.field === selectedField)
    .map((row) => ({
      value: row.exampleValues || '',
    }))
    .filter((row) => row.value !== '');

  const columns = [
    {
      Header: 'Example Values',
      accessor: 'value',
      filter: { enabled: true },
    },
  ];
  return (
    <div className="space-y-4">
      {!isLoading && (!Array.isArray(data) || data.length === 0) ? (
        <div className="text-gray-600">No field information available at this time.</div>
      ) : (
        <>
          <div>
            <h2 className="text-xl font-semibold mb-2">{selectedField}</h2>
            <p className="text-gray-600">{description}</p>
          </div>
          <FormSelectNonHookForm
            fieldKey="field-selector"
            label="Select Field"
            value={selectedField}
            onChange={setSelectedField}
            options={fieldOptions}
            isLoading={isLoading}
          />

          {examples.length > 0 ? (
            <FlexListTable columns={columns} data={examples} hiddenColumnIds={[]} />
          ) : (
            <div className="text-gray-600">
              Sorry we haven&apos;t got examples for that particular field
            </div>
          )}
        </>
      )}
    </div>
  );
}

FieldExampleValueHelper.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default FieldExampleValueHelper;
