// src/services/api/index.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'cookie';

import { handleDjangoDecimalFields } from 'utils/index';

const myBaseQuery = fetchBaseQuery({
  baseUrl: '/api/v1/',
  prepareHeaders: (headers, { getState }) => {
    // Retrieve CSRF token from cookies
    const { csrftoken } = cookie.parse(document.cookie);
    if (csrftoken) {
      headers.set('X-CSRFTOKEN', csrftoken);
    }

    // Retrieve tokens from Redux state
    const { accessToken } = getState().auth;

    if (accessToken) {
      // If OAuth2 access token exists, use it with Bearer scheme
      headers.set('Authorization', `JWT ${accessToken}`);
    }

    const { accountOverride } = getState().auth;
    if (accountOverride) {
      headers.set('X-Account-Override', accountOverride);
    }

    return headers;
  },
});

// Handle Django Decimal fields
const customBaseQuery = async (args, api, extraOptions) => {
  const rawResponse = await myBaseQuery(args, api, extraOptions);
  const transformedData = handleDjangoDecimalFields(rawResponse.data);
  return { ...rawResponse, body: transformedData };
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    /***
     * Auth
     */
    getCurrentUser: builder.query({
      query: (params = {}) => ({ url: 'auth/user/me/', params }),
      keepUnusedDataFor: Infinity,
      providesTags: ['CurrentUser'],
    }),
    updateCurrentUser: builder.mutation({
      query: (updatedUserData) => ({
        url: 'auth/user/me/',
        method: 'PATCH',
        body: updatedUserData,
      }),
      invalidatesTags: ['CurrentUser'],
    }),

    /***
     * Accounts
     */
    getAccounts: builder.query({
      query: (params = {}) => ({
        url: 'accounts/',
        params,
      }),
      keepUnusedDataFor: Infinity,
      providesTags: ['Accounts'],
    }),
    getCurrentAccount: builder.query({
      query: () => 'accounts/current-account/',
      providesTags: ['CurrentAccount'],
    }),
    getAccountById: builder.query({
      query: (id) => ({
        url: `accounts/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'Accounts', id }],
    }),
    addAccount: builder.mutation({
      query: (account) => ({ url: 'accounts/', method: 'POST', body: account }),
      invalidatesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `accounts/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Accounts', id }],
    }),
    partialUpdateAccount: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `accounts/${id}/`, method: 'PATCH', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Accounts', id }],
    }),
    deleteAccount: builder.mutation({
      query: (id) => ({ url: `accounts/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'Accounts', id }],
    }),

    // Users
    getUsersWithCapacity: builder.query({
      query: ({ year, month, ...params }) => ({ url: `user/${year}/${month}/`, params }),
      providesTags: ['Users'],
    }),
    getUsers: builder.query({
      query: (params = {}) => ({ url: 'auth/user/', params, keepUnusedDataFor: 0 }),
      providesTags: ['Users'],
    }),
    getUserById: builder.query({
      query: (id) => `auth/user/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),
    addUser: builder.mutation({
      query: (user) => ({ url: 'auth/user/', method: 'POST', body: user }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `auth/user/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({ url: `auth/user/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'Users', id }],
    }),
    partialUpdateUser: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `auth/user/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
    }),

    /***
     * Pipelines
     */
    getPipelines: builder.query({
      query: (params = {}) => ({
        url: 'pipelines/',
        params,
      }),
      keepUnusedDataFor: Infinity,
      providesTags: ['pipeline'],
    }),
    getPipelineById: builder.query({
      query: (id) => ({
        url: `pipelines/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'pipeline', id }],
    }),
    addPipeline: builder.mutation({
      query: (account) => ({ url: 'pipelines/', method: 'POST', body: account }),
      invalidatesTags: ['pipeline'],
    }),
    updatePipeline: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `pipelines/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'pipeline', id }],
    }),
    deletePipeline: builder.mutation({
      query: (id) => ({ url: `pipelines/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'pipeline', id }],
    }),
    runPipeline: builder.mutation({
      query: ({ id, run_type, full_refresh }) => ({
        url: `pipelines/${id}/run/`,
        method: 'POST',
        body: { run_type, full_refresh },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PipelineRun', id },
        'PipelineRuns', // Add this to invalidate all pipeline runs
      ],
    }),
    updatePipelineDbtProject: builder.mutation({
      query: (id) => ({
        url: `pipelines/${id}/update-dbt-project/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'pipeline', id }],
    }),
    getLatestPipelineRun: builder.query({
      query: (id) => ({
        url: `pipelines/${id}/latest-run/`,
      }),
      transformResponse: (response, meta, arg) => {
        if (meta.response.status === 204) {
          return null;
        }
        return response;
      },
      providesTags: (result, error, id) => [{ type: 'PipelineRun', id }],
    }),
    generateDatabaseDbtProject: builder.query({
      query: (id) => ({
        url: `pipelines/${id}/generate-database-dbt-project/`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'pipeline', id }],
    }),
    getPipelineGithubFile: builder.query({
      query: ({ id, file_path, use_template, macro_name }) => ({
        url: `pipelines/${id}/get-github-file/`,
        params: { file_path, use_template, macro_name },
      }),
      providesTags: (result, error, { id }) => [{ type: 'PipelineGithubFile', id }],
    }),
    createGithubFile: builder.mutation({
      query: ({ id, file_path, content, commit_message, use_template }) => ({
        url: `pipelines/${id}/create-github-file/`,
        method: 'POST',
        body: { file_path, content, commit_message, use_template },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'pipeline', id }],
    }),

    updateGithubFile: builder.mutation({
      query: ({ id, file_path, content, use_template, macro_name }) => ({
        url: `pipelines/${id}/update-github-file/`,
        method: 'POST',
        body: { file_path, content, use_template, macro_name },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'pipeline', id }],
    }),
    // Add these inside the `endpoints` object
    getDbtPackages: builder.query({
      query: (pipelineId) => ({
        url: `pipelines/${pipelineId}/get-dbt-packages/`,
      }),
      providesTags: (result, error, pipelineId) => [{ type: 'Pipeline', id: pipelineId }],
    }),
    getDbtPackage: builder.query({
      query: ({ pipelineId, packageName }) => ({
        url: `pipelines/${pipelineId}/get-dbt-package/`,
        params: { package_name: packageName },
      }),
      providesTags: (result, error, { pipelineId }) => [{ type: 'Pipeline', id: pipelineId }],
    }),

    updateDbtPackage: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `pipelines/${id}/update-dbt-package/`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Pipeline', id }],
    }),

    /***
     * Pipeline Runs
     */
    getPipelineRuns: builder.query({
      query: ({ pipelineId, page = 1, pageSize = 10 }) => ({
        url: 'pipeline-runs/',
        params: {
          pipeline: pipelineId,
          page,
          page_size: pageSize,
        },
      }),
      providesTags: (result, error, { pipelineId }) => [{ type: 'PipelineRuns', id: pipelineId }],
      transformResponse: (response) => ({
        results: response.results,
        totalCount: response.count,
        nextPage: response.next,
        prevPage: response.previous,
      }),
    }),
    getPipelineRunById: builder.query({
      query: (id) => ({
        url: `pipeline-runs/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'PipelineRun', id }],
    }),
    setPipelineRunStatus: builder.mutation({
      query: ({ id, did_run_succeed, run_summary }) => ({
        url: `pipeline-runs/${id}/set-summary/`,
        method: 'POST',
        body: { did_run_succeed, run_summary },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PipelineRun', id },
        { type: 'PipelineRuns' },
      ],
    }),

    /***
     * SourceGA4BigQuery
     */
    getSourceGA4BigQueries: builder.query({
      query: (params = {}) => ({ url: 'source/ga4_bigquery/', params }),
      providesTags: ['SourceGA4BigQuery'],
    }),
    getSourceGA4BigQueryById: builder.query({
      query: (id) => ({ url: `source/ga4_bigquery/${id}/` }),
      providesTags: (result, error, id) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    addSourceGA4BigQuery: builder.mutation({
      query: (sourceGA4BigQuery) => ({
        url: 'source/ga4_bigquery/',
        method: 'POST',
        body: sourceGA4BigQuery,
      }),
      invalidatesTags: ['SourceGA4BigQuery'],
    }),
    updateSourceGA4BigQuery: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `source/ga4_bigquery/${id}/`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    deleteSourceGA4BigQuery: builder.mutation({
      query: (id) => ({ url: `source/ga4_bigquery/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    partialUpdateSourceGA4BigQuery: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `source/ga4_bigquery/${id}/`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    // Update the existing updateDbtProjectFile mutation
    updateDbtProjectFile: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `source/ga4_bigquery/${id}/update-dbt-project-file/`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    // Update the existing updateDbtProjectFile mutation
    partialUpdateDbtProjectFile: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `source/ga4_bigquery/${id}/partial-update-dbt-project-file/`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    updateDbtProjectFileListProperty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `source/ga4_bigquery/${id}/update-dbt-project-file-list-property/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'SourceGA4BigQuery', id }],
    }),
    // Add the new getDbtProjectFile query
    getDbtProjectFile: builder.query({
      query: (id) => ({
        url: `source/ga4_bigquery/${id}/get-dbt-project-file/`,
      }),
      providesTags: (result, error, id) => [{ type: 'SourceGA4BigQuery', id }],
    }),

    // Queued Jobs
    getQueuedJobs: builder.query({
      query: (params = {}) => ({ url: 'queued_jobs/', params }),
      providesTags: ['QueuedJobs'],
    }),
    getQueuedJobById: builder.query({
      query: (id) => ({ url: `queued_jobs/${id}/` }),
      providesTags: (result, error, id) => [{ type: 'QueuedJobs', id }],
    }),
    addQueuedJob: builder.mutation({
      query: (queuedJob) => ({ url: 'queued_jobs/', method: 'POST', body: queuedJob }),
      invalidatesTags: ['QueuedJobs'],
    }),
    updateQueuedJob: builder.mutation({
      query: ({ id, ...rest }) => ({ url: `queued_jobs/${id}/`, method: 'PUT', body: rest }),
      invalidatesTags: (result, error, { id }) => [{ type: 'QueuedJobs', id }],
    }),
    deleteQueuedJob: builder.mutation({
      query: (id) => ({ url: `queued_jobs/${id}/`, method: 'DELETE' }),
      invalidatesTags: (result, error, id) => [{ type: 'QueuedJobs', id }],
    }),

    /*
      BigQuery Datasets
    */
    getGoogleBigQueryProjects: builder.query({
      query: () => ({ url: 'external/google-bq-projects/' }),
      providesTags: ['GoogleBigQueryProjects'],
    }),
    getGA4Properties: builder.query({
      query: ({ accountId, projectId }) => ({
        url: 'external/ga4/properties/',
        params: {
          account_id: accountId,
          project_id: projectId,
        },
      }),
      providesTags: ['GoogleGA4Properties'],
    }),
    getGA4PropertyById: builder.query({
      query: ({ propertyId, projectId }) => ({
        url: `external/ga4/properties/${propertyId}/`,
        params: {
          project_id: projectId,
        },
      }),
      providesTags: (result, error, { propertyId }) => [
        { type: 'GoogleGA4Properties', id: propertyId },
      ],
    }),
    getAllGA4PropertySummaries: builder.query({
      query: ({ projectId }) => ({
        url: 'external/ga4/properties/all_property_summaries/',
        params: {
          project_id: projectId,
        },
      }),
      providesTags: ['GoogleGA4Properties'],
    }),
    getBigQueryDatasets: builder.query({
      query: ({ project_id, ignore_ga4_raw_exports, ...params }) => ({
        url: 'external/bigquery/datasets/',
        params: {
          project_id,
          ignore_ga4_raw_exports,
          ...params,
        },
      }),
      providesTags: ['GoogleBigQueryDatasets'],
    }),
    createBigQueryDataset: builder.mutation({
      query: (datasetData) => ({
        url: 'external/bigquery/datasets/',
        method: 'POST',
        body: datasetData,
      }),
      invalidatesTags: ['GoogleBigQueryDatasets'],
    }),
    getBigQueryTableSchema: builder.query({
      query: ({ project_id, dataset_id, table_id }) => ({
        url: 'external/bigquery/datasets/table-schema/',
        params: { project_id, dataset_id, table_id },
      }),
      providesTags: ['BigQueryTableSchema'],
    }),

    /*
      Google Analytics Accounts
    */
    getGA4Accounts: builder.query({
      query: (params = {}) => ({
        url: 'external/ga4/accounts/',
        params,
      }),
      providesTags: ['GoogleAnalyticsAccounts'],
    }),

    // GA4 BigQuery Links
    getGA4BigQueryLinks: builder.query({
      query: ({ propertyId, projectId, ...params }) => ({
        url: 'external/ga4/bigquery-links/',
        params: {
          property_id: propertyId,
          project_id: projectId,
          ...params,
        },
      }),
      providesTags: ['GA4BigQueryLinks'],
    }),
    getGA4BigQueryLinkById: builder.query({
      query: ({ propertyId, gcloud_project_id }) => ({
        url: 'external/ga4/bigquery-links/',
        params: {
          property_id: propertyId,
          project_id: gcloud_project_id,
        },
      }),
      providesTags: (result, error, { propertyId }) => [
        { type: 'GA4BigQueryLinks', id: propertyId },
      ],
    }),
    addGA4BigQueryLink: builder.mutation({
      query: ({ linkData, projectId }) => ({
        url: 'external/ga4/bigquery-links/',
        method: 'POST',
        body: { ...linkData, project_id: projectId },
      }),
      invalidatesTags: ['GA4BigQueryLinks'],
    }),
    updateGA4BigQueryLink: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `external/ga4/bigquery-links/${id}/update_ga4_bigquery_link/`,
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'GA4BigQueryLinks', id }],
    }),
    deleteGA4BigQueryLink: builder.mutation({
      query: (id) => ({
        url: `external/ga4/bigquery-links/${id}/delete_ga4_bigquery_link/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'GA4BigQueryLinks', id }],
    }),

    // Get GA4 Event Sampling
    getGA4EventSampling: builder.query({
      query: ({ property_id, project_id, query_type = 'get_sample_events' }) => ({
        url: 'external/ga4-event-sampling/',
        params: { property_id, project_id, query_type },
      }),
      providesTags: ['GA4EventSampling'],
    }),

    // Update the validateGA4CustomChannelGrouping mutation
    validateGA4CaseStatement: builder.mutation({
      query: ({ project_id, dataset, case_statement, validation_type }) => ({
        url: 'external/validate/ga4/case-statement/',
        method: 'POST',
        body: { project_id, dataset, case_statement, validation_type },
      }),
    }),

    // Utils
    utilsFormatSQL: builder.mutation({
      query: (sqlQuery) => ({
        url: 'utils/format-sql/',
        method: 'POST',
        body: { sql_query: sqlQuery },
      }),
    }),

    // Github
    getRepoVersions: builder.query({
      query: (repoUrl) => ({
        url: 'external/github/repo-versions/',
        params: { repo_url: repoUrl },
      }),
      providesTags: (result, error, repoUrl) => [{ type: 'GitHubRepoVersions', id: repoUrl }],
    }),

    // Get Google API Auth Status
    getGoogleAPIAuthStatus: builder.query({
      query: () => ({
        url: 'google-auth-flow/status/',
      }),
      providesTags: ['GoogleAuthStatus'],
    }),

    /***
     * Paddle Billing
     */
    getPaddleCustomers: builder.query({
      query: (params = {}) => ({
        url: 'paddle/customers/',
        params,
      }),
      providesTags: ['PaddleCustomers'],
    }),
    getPaddleCustomerById: builder.query({
      query: (id) => ({
        url: `paddle/customers/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'PaddleCustomers', id }],
    }),
    getPaddleSubscriptions: builder.query({
      query: (params = {}) => ({
        url: 'paddle/subscriptions/',
        params,
      }),
      providesTags: (result) => [
        'PaddleSubscriptions',
        ...(result?.results?.map((sub) => ({ type: 'PaddleSubscriptions', id: sub.id })) || []),
      ],
    }),
    getPaddleSubscriptionById: builder.query({
      query: (id) => ({
        url: `paddle/subscriptions/${id}/`,
      }),
      providesTags: (result, error, id) => [{ type: 'PaddleSubscriptions', id }],
    }),

    // Paddle Subscription Management
    modifySubscriptionItemQuantity: builder.mutation({
      query: ({ subscriptionId, priceId, quantity }) => ({
        url: `paddle/subscriptions/${subscriptionId}/modify-item-quantity/`,
        method: 'POST',
        body: { price_id: priceId, quantity },
      }),
      invalidatesTags: (result, error, { subscriptionId }) => [
        { type: 'PaddleSubscriptions', id: subscriptionId },
        'PaddleSubscriptions',
        'PaddleActivePlans',
      ],
    }),

    changeSubscriptionItem: builder.mutation({
      query: ({ id, old_price_id, price_id, quantity, proration_billing_mode }) => ({
        url: `paddle/subscriptions/${id}/change-subscription-item/`,
        method: 'POST',
        body: { old_price_id, price_id, quantity, proration_billing_mode },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PaddleSubscriptions', id },
        'PaddleSubscriptions',
        'PaddleActivePlans',
      ],
    }),

    cancelSubscription: builder.mutation({
      query: ({ id, effective_from }) => ({
        url: `paddle/subscriptions/${id}/cancel-subscription/`,
        method: 'POST',
        body: { effective_from },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PaddleSubscriptions', id },
        'PaddleSubscriptions',
        'PaddleActivePlans',
      ],
    }),

    // Paddle Billing section
    getPaddleActivePlans: builder.query({
      query: ({ reference_date } = {}) => ({
        url: 'paddle/active-plans/',
        params: reference_date ? { reference_date } : {},
      }),
      providesTags: (result) => [
        'PaddleActivePlans',
        ...(result?.map((plan) => ({ type: 'PaddleActivePlans', id: plan.id })) || []),
      ],
    }),

    // Add this new mutation
    activateSubscription: builder.mutation({
      query: (id) => ({
        url: `paddle/subscriptions/${id}/activate/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'PaddleSubscriptions', id },
        'PaddleSubscriptions',
        'PaddleActivePlans',
      ],
    }),

    // Add this to the endpoints object
    checkGCPPermissions: builder.query({
      query: (project_id) => ({
        url: 'external/google-cloud/permissions/',
        params: { project_id },
      }),
      providesTags: ['GCPPermissions'],
    }),

    deleteGA4PipelineTables: builder.mutation({
      query: ({ id, include_copied_events }) => ({
        url: `pipelines/${id}/delete-ga4-pipeline-tables/`,
        method: 'POST',
        body: { include_copied_events },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'pipeline', id }],
    }),

    // Add this new endpoint
    pauseSubscription: builder.mutation({
      query: ({ subscriptionId, effective_from, resume_at }) => ({
        url: `paddle/subscriptions/${subscriptionId}/pause/`,
        method: 'POST',
        body: {
          effective_from,
          resume_at,
        },
      }),
      invalidatesTags: (result, error, { subscriptionId }) => [
        { type: 'PaddleSubscriptions', id: subscriptionId },
        'PaddleSubscriptions',
        'PaddleActivePlans',
      ],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdateCurrentUserMutation,
  useGetAccountsQuery,
  useGetAccountByIdQuery,
  useAddAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  usePartialUpdateAccountMutation,
  // Users
  useGetUsersWithCapacityQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  usePartialUpdateUserMutation,

  // Pipeline
  useGetPipelinesQuery,
  useGetPipelineByIdQuery,
  useAddPipelineMutation,
  useUpdatePipelineMutation,
  useDeletePipelineMutation,
  useRunPipelineMutation,
  useUpdatePipelineDbtProjectMutation,
  useGetLatestPipelineRunQuery,
  useGenerateDatabaseDbtProjectQuery,
  useGetPipelineGithubFileQuery,
  useGetDbtPackagesQuery,
  useGetDbtPackageQuery,
  useUpdateDbtPackageMutation,

  // Pipeline Runs
  useGetPipelineRunsQuery,
  useSetPipelineRunStatusMutation,
  useGetPipelineRunByIdQuery,

  // GA4 BigQuery Export
  useGetSourceGA4BigQueriesQuery,
  useGetSourceGA4BigQueryByIdQuery,
  useAddSourceGA4BigQueryMutation,
  useUpdateSourceGA4BigQueryMutation,
  useDeleteSourceGA4BigQueryMutation,
  usePartialUpdateSourceGA4BigQueryMutation,
  useUpdateDbtProjectFileMutation,
  usePartialUpdateDbtProjectFileMutation,
  useGetDbtProjectFileQuery,
  useUpdateDbtProjectFileListPropertyMutation,

  // Queued Jobs
  useGetQueuedJobsQuery,
  useGetQueuedJobByIdQuery,
  useAddQueuedJobMutation,
  useUpdateQueuedJobMutation,
  useDeleteQueuedJobMutation,

  // Google entity data
  useGetGoogleBigQueryProjectsQuery,
  useGetBigQueryDatasetsQuery,
  useCreateBigQueryDatasetMutation,

  // GA4 properties
  useGetGA4PropertiesQuery,
  useGetGA4PropertyByIdQuery,
  useLazyGetGA4PropertyByIdQuery,
  useGetAllGA4PropertySummariesQuery,

  // GA4 Accounts
  useGetGA4AccountsQuery,
  useGetCurrentAccountQuery,

  // GA4 BigQuery Links
  useGetGA4BigQueryLinksQuery,
  useGetGA4BigQueryLinkByIdQuery,
  useAddGA4BigQueryLinkMutation,
  useUpdateGA4BigQueryLinkMutation,
  useDeleteGA4BigQueryLinkMutation,

  // Get GA4 Event Sampling
  useGetGA4EventSamplingQuery,

  // BigQuery
  useGetBigQueryTableSchemaQuery,

  // Validate GA4 Custom Channel Grouping
  useValidateGA4CaseStatementMutation,

  // Utils
  useUtilsFormatSQLMutation,

  // Updating Github Files
  useCreateGithubFileMutation,
  useUpdateGithubFileMutation,

  // Github
  useGetRepoVersionsQuery,

  // ... other exports ...
  useGetGoogleAPIAuthStatusQuery,

  // ... other exports ...

  // Paddle Billing
  useGetPaddleCustomersQuery,
  useGetPaddleCustomerByIdQuery,
  useGetPaddleSubscriptionsQuery,
  useGetPaddleSubscriptionByIdQuery,
  useGetPaddleActivePlansQuery,

  // Paddle Subscription Management
  useModifySubscriptionItemQuantityMutation,
  useChangeSubscriptionItemMutation,
  useCancelSubscriptionMutation,
  useActivateSubscriptionMutation,
  usePauseSubscriptionMutation,

  // Check GCP Permissions
  useCheckGCPPermissionsQuery,

  // Delete GA4 Pipeline Tables
  useDeleteGA4PipelineTablesMutation,
} = api;

export default api;
