import { z } from 'zod';

export const userSchemaForm = z.object({
  id: z.number().optional(),
  name: z.string(),
  email: z.string().email(),
  hourly_rate: z.number(),
  role: z.string(),
  team: z.number(), // ForeignKey represented as a number
  account: z.number(), // ForeignKey represented as a number
  tasks: z.array(z.number()), // Assuming tasks are referenced by their IDs
});

export const userSchema = z.object({
  id: z.number().optional(),
  name: z.string(),
  email: z.string().email(),
  hourly_rate: z.number(),
  role: z.string(),
  team: z.number(), // ForeignKey represented as a number
  account: z.number(), // ForeignKey represented as a number
  tasks: z.array(z.number()), // Assuming tasks are referenced by their IDs
});

export const fieldInfoDisplayUser = {
  id: { name: 'ID' },
  name: { name: 'Name', description: 'The name of the user' },
  email: { name: 'Email', description: 'The email of the user' },
};
