import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import Tooltip from 'components/Tooltip/Tooltip'; // Assuming you have a Tooltip component
import * as ROUTES from 'routes/constants';
import { useGetCurrentUserQuery, useGetSourceGA4BigQueryByIdQuery } from 'services/rtkApi';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 * SourceGA4BigQueryFormNavigation component
 * @param {Object} props - Component props
 * @param {boolean} props.isUpdate - Whether this is a create or update view
 * @returns {React.Component} Vertical navigation for GA4 BigQuery source form
 */
function SourceGA4BigQueryFormNavigation({ isUpdate }) {
  const { pipelineId, id } = useParams();
  const location = useLocation();
  const { data: currentUser } = useGetCurrentUserQuery();

  // Only fetch sourceData if isUpdate is true
  const { data: sourceData } = useGetSourceGA4BigQueryByIdQuery(id, {
    skip: !isUpdate,
  });

  const isInitialSetupIncomplete =
    isUpdate &&
    (sourceData?.furthest_pipeline_build_status_reached === 'not_started' ||
      sourceData?.furthest_pipeline_build_status_reached === 'raw_data_copied');

  const navigationLinks = [
    { divider: 'Required', type: 'subtitle' },
    { name: 'Main', route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM },
    { divider: 'Optional', type: 'subtitle' },
    {
      name: 'Extract extra data',
      route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_COLUMNS_TO_UNNEST,
    },
    { name: 'Ecommerce', route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ECOMMERCE },
    {
      name: 'Conversions',
      route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_CONVERSIONS_NEW,
    },
    {
      name: 'Spam Filter',
      route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_SPAM_FILTER,
    },
    { name: 'Channel Groupings', route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_CHANNEL_GROUPINGS },
    { name: 'Advanced Config', route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ADVANCED_CONFIG },
    // { name: 'Event Properties', route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_EVENT_PROPERTIES },
    {
      name: 'Session Properties',
      route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_SESSION_PROPERTIES,
    },
    { name: 'User Properties', route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_USER_PROPERTIES },
    {
      name: 'Advanced Config BQ',
      route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ADVANCED_CONFIG_BQ,
    },
    // {
    //   name: 'Attribution Models',
    //   route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_ATTRIBUTION,
    // },
    ...(currentUser?.is_superuser
      ? [
          {
            name: 'Raw JSON',
            route: ROUTES.SOURCE_GA4_BIGQUERY_UPDATE_FORM_RAW,
          },
        ]
      : []),
  ];

  return (
    <nav
      aria-label="Form Navigation"
      className="w-58 flex-shrink-0 border-r border-gray-200 pt-5 pr-8"
    >
      <ul role="list" className="space-y-1">
        {navigationLinks.map((navigationLink, index) => {
          if (navigationLink.type === 'subtitle') {
            return (
              <li key={`subtitle-${index}`}>
                <div className="text-xs font-semibold leading-6 text-gray-400 mt-4 mb-2">
                  {navigationLink.divider}
                </div>
              </li>
            );
          }

          // const isDisabled = false;
          const isDisabled =
            (!isUpdate && navigationLink.name !== 'Main') ||
            (isInitialSetupIncomplete &&
              navigationLink.name !== 'Main' &&
              navigationLink.name !== 'Extract extra data');

          const isActive =
            location.pathname ===
            navigationLink.route.replace(':pipelineId', pipelineId).replace(':id', id);

          const tabContent = (
            <span
              className={classNames(
                isDisabled ? 'opacity-50 cursor-not-allowed' : '',
                'group flex w-full gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6',
                isActive
                  ? 'bg-gray-50 text-red-damask-700'
                  : 'text-gray-700 hover:bg-gray-50 hover:text-red-damask-600'
              )}
            >
              {navigationLink.name}
            </span>
          );

          return (
            <li key={navigationLink.name}>
              {isDisabled ? (
                <Tooltip
                  position="right"
                  content="You need to 'Continue Setup Run' for the pipeline. (It's in the top right hand corner)"
                >
                  {tabContent}
                </Tooltip>
              ) : (
                <Link
                  to={navigationLink.route.replace(':pipelineId', pipelineId).replace(':id', id)}
                  className="block"
                >
                  {tabContent}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

SourceGA4BigQueryFormNavigation.propTypes = {
  isUpdate: PropTypes.bool.isRequired,
};

export default SourceGA4BigQueryFormNavigation;
