import { useCallback, useState } from 'react';

import { useUpdateDbtProjectFileListPropertyMutation } from 'services/rtkApi';

/**
 * Empty session property template.
 */
export const emptySessionTemplateStringNumber = {
  name: 'new_session_property_string_number',
  schema_template: 'string_number',
  propertyConditionBlock: {
    propertyConditions: [
      {
        conditionBlock: {
          andOr: 'and',
          conditions: [],
        },
      },
    ],
  },
};

export const emptySessionTemplateBool = {
  ...emptySessionTemplateStringNumber,
  name: 'new_session_property_true_false',
  schema_template: 'bool',
};

/**
 * Hook for managing GA4 session property templates and actions.
 *
 * @param {string} id - The project ID
 * @param {Array} initialSessionProperties - The initial session properties
 * @param {Array} currentSessionProperties - The current session properties from parent
 */
const useGA4SessionPropertyTemplates = (id, initialSessionProperties, currentSessionProperties) => {
  const [updateDbtProjectFileListProperty] = useUpdateDbtProjectFileListPropertyMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [sessionProperties, setSessionProperties] = useState(initialSessionProperties);

  const sessionPropertyTemplates = [
    {
      title: 'Session Last Page',
      description: 'Adds a session property for the last page visited',
      template: {
        name: 'session_last_page',
        schema_template: 'string_number',
        propertyConditionBlock: {
          _comment:
            "Get the last page_location seen in the session. There are no conditions, so we're looking at all events",
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [],
              },
              value: {
                col: 'dim__page_location',
                agg: 'last_value',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Last Content Page',
      description: 'Adds a session property for the last content page visited',
      template: {
        name: 'last_content_page',
        schema_template: 'string_number',
        propertyConditionBlock: {
          _comment:
            "Get the last page_location, but only look at events where the last event matches the regex '/blog/'",
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'dim__page_location',
                    operator: 'regex_contains',
                    value: '/blog/',
                  },
                ],
              },
              value: {
                col: 'dim__page_location',
                agg: 'last_value',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Searches Count',
      description: 'Adds a session property for counting searches',
      template: {
        name: 'searches_count',
        dim_or_met: 'metric',
        schema_template: 'string_number',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'ep__search_term',
                    operator: 'is',
                    negative_match: true,
                    value: null,
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'count',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Session > 5 pageviews',
      description: 'Adds a session property for counting sessions with more than 5 pageviews',
      template: {
        name: 'session_pageviews_count_gt_5',
        dim_or_met: 'dim',
        schema_template: 'bool',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: 'regex_contains',
                    value: 'page_view',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'count',
              },
              compare: {
                operator: '>',
                value: 5,
              },
            },
          ],
        },
      },
    },
    {
      title: 'Sales PDF Downloads Per Session',
      description: 'Adds a session property for counting sales PDF downloads',
      schema_template: 'string_number',
      template: {
        name: 'sales_pdf_downloads_per_session',
        attribution_table: 'include',
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: 'regex_contains',
                    value: 'r"content_download"',
                  },
                  {
                    col: 'ep__tag_name',
                    operator: 'regex_contains',
                    value: '(?i)Sales PDF',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'count',
              },
            },
          ],
        },
      },
    },
  ];

  const handleAddSessionProperty = useCallback(
    (template) => {
      setIsUpdating(true);
      const updatedTemplate = { ...template };

      const getUniqueEventName = (baseName, existingEvents) => {
        let eventNumber = 0;
        let newName = baseName;

        const isNameTaken = (name) => existingEvents.some((event) => event.name === name);

        while (isNameTaken(newName)) {
          eventNumber += 1;
          newName = `${baseName}${eventNumber}`;
        }

        return newName;
      };

      updatedTemplate.name = getUniqueEventName(updatedTemplate.name, currentSessionProperties);
      updatedTemplate.schema_template = template.schema_template;

      updateDbtProjectFileListProperty({
        id,
        property_name: 'derived_session_properties',
        operation: 'add',
        item: updatedTemplate,
      })
        .unwrap()
        .then(() => {
          setSessionProperties([...sessionProperties, updatedTemplate]);
        })
        .catch((error) => {
          console.error('Failed to add conversion:', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [currentSessionProperties, updateDbtProjectFileListProperty, id]
  );

  const handleDeleteSessionProperty = useCallback(
    (indexToDelete) => {
      setIsUpdating(true);
      updateDbtProjectFileListProperty({
        id,
        property_name: 'derived_session_properties',
        operation: 'delete',
        index: indexToDelete,
      })
        .unwrap()
        .then(() => {
          setSessionProperties(sessionProperties.filter((_, index) => index !== indexToDelete));
        })
        .catch((error) => {
          console.error('Failed to delete conversion:', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [sessionProperties, updateDbtProjectFileListProperty, id]
  );

  const emptyStateActions = sessionPropertyTemplates.map(
    ({ title, description, icon, template }) => ({
      title,
      description,
      icon,
      action: () => handleAddSessionProperty(template),
    })
  );

  return {
    emptyStateActions,
    handleAddSessionProperty,
    handleDeleteSessionProperty,
    isUpdating,
  };
};

export default useGA4SessionPropertyTemplates;
