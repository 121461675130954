import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Adjust the import path as needed
import { socialLoginRequest } from 'services/auth/authSlice';
import LoggedOutLayout from 'templates/LoggedOutLayout';

const SocialLoginRedirect = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');
    const provider = 'google-oauth2';

    if (code) {
      dispatch(socialLoginRequest({ provider, code, state }));
      navigate('/'); // Redirect to the homepage or dashboard
    } else {
      // navigate('/login'); // Redirect to login page if no code is present
    }
  }, [dispatch, location, navigate]);

  return (
    <LoggedOutLayout>
      <div>Loading...</div>
      <div>
        <LoadingSpinner />
      </div>
    </LoggedOutLayout>
  );
};

export default SocialLoginRedirect;
