import { XCircleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ClickableListItem = ({ item, index, onItemClick, onItemDelete }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onItemClick) {
      onItemClick(index);
    } else if (item.url && !item.disabled) {
      navigate(item.url);
    }
  };

  return (
    <li
      className={`relative flex justify-between gap-x-6 px-4 py-5 ${
        item.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer'
      } sm:px-6`}
      onClick={handleClick}
      role="button"
      tabIndex={item.disabled ? -1 : 0}
      onKeyDown={(e) => {
        if (!item.disabled && (e.key === 'Enter' || e.key === ' ')) {
          handleClick();
        }
      }}
    >
      <div className="flex min-w-0 gap-x-4 flex-grow">
        <ItemContent item={item} />
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        {onItemDelete && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onItemDelete(index);
            }}
            disabled={item.disabled}
            className="text-gray-400 hover:text-gray-500"
            aria-label="Delete item"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
        )}
        <input
          type="checkbox"
          checked={item.isComplete || false}
          readOnly
          className="h-4 w-4 rounded border-gray-300 text-deep-sea-green-600 focus:ring-indigo-600"
        />
      </div>
    </li>
  );
};

// eslint-disable-next-line react/prop-types
const ItemContent = ({ item }) => (
  <div className="min-w-0 flex-auto">
    <p className="text-sm font-semibold leading-6 text-gray-900 pb-0">{item.name}</p>
    {React.isValidElement(item.description) ? (
      item.description
    ) : Array.isArray(item.description) ? (
      <div className="mt-1 space-y-1">
        {item.description.map((desc, index) => (
          <p key={index} className="flex text-xs leading-5 text-gray-500 pb-0">
            {desc}
          </p>
        ))}
      </div>
    ) : (
      <p className="mt-1 flex text-xs leading-5 text-gray-500 pb-0">{item.description}</p>
    )}
  </div>
);

/**
 * ChecklistStacked component for displaying a list of items with checkboxes.
 *
 * @param {Object} props
 * @param {Array} props.items - List of items to display
 * @param {function} props.onItemClick - Function to call when an item is clicked or checkbox is toggled
 * @param {function} [props.onItemDelete] - Optional function to call when an item is deleted
 * @param {Object} [props.header] - Optional header configuration
 * @param {string} [props.header.title] - Header title text
 * @param {string} [props.header.description] - Header description text
 * @param {string} [props.className] - Additional CSS classes
 */
const ChecklistStacked = ({ items, onItemClick, onItemDelete, header, className }) => {
  return (
    <div className={`bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl ${className || ''}`}>
      {header && (
        <div className="px-4 py-5 sm:px-6 border-b border-gray-100">
          <h3 className="text-base font-semibold text-gray-900">{header.title}</h3>
          {header.description && (
            <p className="mt-1 text-sm text-gray-500 pb-0">{header.description}</p>
          )}
        </div>
      )}
      <ul className="divide-y divide-gray-100">
        {items.map((item, index) => (
          <ClickableListItem
            key={item.id || index}
            item={item}
            index={index}
            onItemClick={onItemClick}
            onItemDelete={onItemDelete}
          />
        ))}
      </ul>
    </div>
  );
};

ChecklistStacked.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.element,
      ]),
      isComplete: PropTypes.bool,
      url: PropTypes.string,
    })
  ).isRequired,
  onItemClick: PropTypes.func,
  onItemDelete: PropTypes.func,
  header: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default ChecklistStacked;
