import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useCheckGCPPermissionsQuery, useGetGoogleBigQueryProjectsQuery } from 'services/rtkApi';

/**
 * Component to check and display GCP permissions status
 * @returns {JSX.Element} GCloud permissions checker component
 */
const GCloudPermissionsChecker = () => {
  const [isManuallyChecking, setIsManuallyChecking] = useState(false);
  const { control, watch } = useForm();
  const googleAPIAuthStatus = useSelector((state) => state.auth.googleAPIAuthStatus);

  // Get projects using the same query as GoogleProjectSwitcher
  const { data: projects, isLoading: isLoadingProjects } = useGetGoogleBigQueryProjectsQuery(
    undefined,
    {
      skip: !googleAPIAuthStatus,
    }
  );

  const selectedProject = watch('project');

  const {
    data: permissionStatus,
    isLoading: isCheckingPermissions,
    refetch,
    error,
  } = useCheckGCPPermissionsQuery(selectedProject || '', {
    skip: !selectedProject,
  });

  const REQUIRED_PERMISSIONS = [
    {
      name: 'serviceusage.services.use',
      role: 'Service Usage Consumer',
      isRequired: true,
    },
    {
      name: 'bigquery.jobs.create',
      role: 'BigQuery Job User',
      isRequired: true,
    },
    {
      name: 'bigquery.jobs.list',
      role: 'BigQuery Job User',
      isRequired: true,
    },
    {
      name: 'bigquery.jobs.listAll',
      role: 'BigQuery Job User',
      isRequired: true,
    },
    {
      name: 'bigquery.tables.create',
      role: 'BigQuery Data Editor',
      isRequired: true,
    },
    {
      name: 'bigquery.tables.update',
      role: 'BigQuery Data Editor',
      isRequired: true,
    },
    {
      name: 'bigquery.tables.delete',
      role: 'BigQuery Data Editor',
      isRequired: true,
    },
    {
      name: 'bigquery.datasets.create',
      role: 'BigQuery Data Editor',
      isRequired: false,
    },
    {
      name: 'bigquery.datasets.delete',
      role: 'BigQuery Data Editor',
      isRequired: false,
    },
  ];

  const columns = [
    {
      Header: 'Permission',
      accessor: 'name',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: 'Required',
      accessor: 'isRequired',
      Cell: ({ value }) => <span className="text-gray-500">{value ? 'Yes' : 'No'}</span>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => {
        if (isCheckingPermissions) {
          return <LoadingSpinner size="sm" />;
        }
        if (value === '-') {
          return <span className="text-gray-500">-</span>;
        }
        return (
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              value ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}
          >
            {value ? 'Granted' : 'Missing'}
          </span>
        );
      },
    },
  ];

  const getPermissionsData = () => {
    if (!permissionStatus) {
      return REQUIRED_PERMISSIONS.map((perm) => ({
        ...perm,
        status: '-',
      }));
    }

    const isAdmin =
      permissionStatus.current_permissions.includes('bigquery.admin') ||
      permissionStatus.current_permissions.includes('resourcemanager.projects.owner');

    return REQUIRED_PERMISSIONS.map((perm) => ({
      ...perm,
      // If admin/owner, all BigQuery permissions are considered granted
      status: isAdmin
        ? perm.name === 'serviceusage.services.use'
          ? permissionStatus.current_permissions.includes(perm.name)
          : true
        : permissionStatus.current_permissions.includes(perm.name),
    }));
  };

  const handleCheckPermissions = async () => {
    if (!selectedProject) return;
    setIsManuallyChecking(true);
    await refetch();
    setIsManuallyChecking(false);
  };

  const hasServiceUsagePermission = permissionStatus?.current_permissions.includes(
    'serviceusage.services.use'
  );

  return (
    <div className="space-y-4">
      <p>
        Once you&apos;ve given us permission you need to make your account has the correct
        permissions. The documentation is here:{' '}
        <a
          href="https://www.pipedout.com/documentation/setup-instructions/creating-your-pipeline/connecting-ga4-to-bigquery#step-3-sharing-permissions-in-google-cloud"
          target="_blank"
          rel="noopener noreferrer"
        >
          sharing permissions in Google Cloud
        </a>
      </p>

      {isLoadingProjects ? (
        <LoadingSpinner text="Loading projects..." className="mt-4" />
      ) : (
        <FormSelect
          fieldKey="project"
          label="Google Project"
          control={control}
          errors={{}}
          options={
            projects?.map((project) => ({
              value: project.id,
              label: project.name,
            })) || []
          }
          isLoading={isLoadingProjects}
          className="w-full max-w-xs mb-4"
        />
      )}

      {/* Add alert if service usage permission is missing */}
      {permissionStatus && !hasServiceUsagePermission && (
        <Alert
          status="warning"
          message="You don't have Service Usage Consumer permission, so we can't check if you have the other permissions."
        />
      )}

      {/* Only show table when not loading */}
      {!isLoadingProjects && (
        <>
          <div className="flex justify-between items-center">
            <Button
              onClick={handleCheckPermissions}
              loading={isManuallyChecking}
              disabled={!selectedProject}
            >
              Check Permissions
            </Button>
          </div>
          <FlexListTable
            columns={columns}
            data={getPermissionsData()}
            initialSort={[{ id: 'status', desc: true }]}
          />
        </>
      )}

      {error && (
        <div className="md:bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error checking permissions</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GCloudPermissionsChecker;
