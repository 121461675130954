import GoogleProjectSwitcher from 'components/GoogleProjectSwitcher/GoogleProjectSwitcher';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * Settings page for managing account-wide default settings
 */
const AccountDefaults = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Account Defaults</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <div className="">
            <GoogleProjectSwitcher />
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInner />
      </div>
    </PageLayout>
  );
};

export default AccountDefaults;
