import { useMemo } from 'react';

import {
  convertCompareValuesBySchema,
  convertStringNumberAtPath,
  convertValuesBySchema,
  validateValuesToIgnore,
} from 'utils/index';

/**
 * Hook to convert values to correct types based on table schema
 * @param {Array} tableSchema - Array of column definitions with name and type
 * @param {boolean} isUserMode - Whether we're in user mode
 * @returns {Function} Conversion function that returns { data, errors }
 */
const useConvertPropertyValueToCorrectDataType = (tableSchema, isUserMode = false) => {
  const schemaMap = useMemo(
    () =>
      tableSchema?.reduce(
        (acc, col) => ({
          ...acc,
          [col.name]: col.type,
        }),
        {}
      ),
    [tableSchema]
  );

  const convertValues = (data) => {
    let processedData = { ...data };
    let errors = [];

    // Convert on_condition_true values
    processedData = convertStringNumberAtPath(processedData, [
      'propertyConditionBlock',
      'propertyConditions',
      '*',
      'value',
      'on_condition_true',
    ]);

    // Convert and validate compare values
    const { data: validatedData, errors: compareErrors } = convertCompareValuesBySchema(
      processedData,
      schemaMap
    );
    processedData = validatedData;
    errors = [...errors, ...compareErrors];

    // Only validate values_to_ignore in user mode

    if (isUserMode) {
      const { data: validatedIgnoreData, errors: ignoreErrors } = validateValuesToIgnore(
        processedData,
        data.schema_template,
        schemaMap
      );
      processedData = validatedIgnoreData;
      errors = [...errors, ...ignoreErrors];
    }

    // Convert condition values using schema
    const { data: schemaData, errors: schemaErrors } = convertValuesBySchema(
      processedData,
      ['propertyConditionBlock', 'propertyConditions', '*', 'conditionBlock', 'conditions', '*'],
      schemaMap
    );
    processedData = schemaData;
    errors = [...errors, ...schemaErrors];

    return { data: processedData, errors };
  };

  return convertValues;
};

export default useConvertPropertyValueToCorrectDataType;
