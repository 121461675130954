import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
    content: null,
    args: {},
  },
  reducers: {
    openDrawer: (state, action) => {
      state.isOpen = true;
      state.content = action.payload.content;
      state.args = action.payload.args || {};
    },
    closeDrawer: (state) => {
      state.isOpen = false;
      state.content = null;
    },
    updateDrawerArgs: (state, action) => {
      state.args = { ...state.args, ...action.payload };
    },
  },
});

export const { openDrawer, closeDrawer, updateDrawerArgs } = drawerSlice.actions;
export default drawerSlice.reducer;
