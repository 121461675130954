import PropTypes from 'prop-types';
import renderSchemaFlatField from '../renderSchemaFlatField';

/**
 * Custom layout for conversion value
 *
 * @param {Object} props - Component props
 * @param {Object} props.fields - The fields to render
 * @param {string} props.name - The name of the field
 * @param {Object} props.control - React Hook Form control object
 * @param {Function} props.register - React Hook Form register function
 * @param {Object} props.errors - Form errors object
 * @param {number} props.depth - Current depth in the form structure
 * @param {Array} props.path - Current path in the form structure
 * @param {Object} props.fieldInfo - Additional field information
 * @param {Object} props.foreignKeyOptions - Foreign key options
 * @param {Object} props.loadingForeignKeys - Loading state for foreign keys
 * @param {Function} props.setValue - React Hook Form setValue function
 * @param {Object} props.autoSetFields - Fields that are automatically set
 */
const CustomLayoutConversionSegment = ({
  fields,
  name,
  control,
  register,
  errors,
  depth,
  path,
  fieldInfo,
  foreignKeyOptions,
  loadingForeignKeys,
  setValue,
  autoSetFields,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-4">
        <div className="flex-1 transition-all duration-200 focus-within:flex-[2]">
          {renderSchemaFlatField({
            schema: fields.col,
            name: `${name}.col`,
            control,
            register,
            errors,
            path: [...path, 'col'],
            fieldInfo,
            foreignKeyOptions,
            loadingForeignKeys,
            autoSetFields,
          })}
        </div>
        <div className="flex-shrink-0 font-medium text-gray-500 mt-4">OR</div>
        <div className="flex-1 transition-all duration-200 focus-within:flex-[2]">
          {renderSchemaFlatField({
            schema: fields.on_condition_true,
            name: `${name}.on_condition_true`,
            control,
            register,
            errors,
            path: [...path, 'on_condition_true'],
            fieldInfo,
            foreignKeyOptions,
            loadingForeignKeys,
            autoSetFields,
          })}
        </div>
      </div>
      <div>
        {renderSchemaFlatField({
          schema: fields.agg,
          name: `${name}.agg`,
          control,
          register,
          errors,
          path: [...path, 'agg'],
          fieldInfo,
          foreignKeyOptions,
          loadingForeignKeys,
          autoSetFields,
        })}
      </div>
      {fields.window && (
        <div>
          {renderSchemaFlatField({
            schema: fields.window,
            name: `${name}.window`,
            control,
            register,
            errors,
            path: [...path, 'window'],
            fieldInfo,
            foreignKeyOptions,
            loadingForeignKeys,
            autoSetFields,
          })}
        </div>
      )}
    </div>
  );
};

CustomLayoutConversionSegment.propTypes = {
  fields: PropTypes.shape({
    col: PropTypes.object.isRequired,
    on_condition_true: PropTypes.object.isRequired,
    agg: PropTypes.object.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  depth: PropTypes.number.isRequired,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  fieldInfo: PropTypes.object,
  foreignKeyOptions: PropTypes.array,
  loadingForeignKeys: PropTypes.array,
  setValue: PropTypes.func.isRequired,
  autoSetFields: PropTypes.object,
};

CustomLayoutConversionSegment.defaultProps = {
  errors: {},
  fieldInfo: {},
  foreignKeyOptions: [],
  loadingForeignKeys: [],
  autoSetFields: {},
};

export default CustomLayoutConversionSegment;
