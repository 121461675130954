import PropTypes from 'prop-types';
import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ModelDisplay from 'components/ModelDisplay/ModelDisplay';
import { fieldInfoDisplayUser } from 'pages/models/users/schema/userSchema';
import { useGetCurrentUserQuery } from 'services/rtkApi';

/**
 * Displays current user data fetched from the API
 * @param {Object} props
 * @param {Function} props.onSuccess - Callback function on successful data fetch
 */
const UserModelDisplay = ({ onSuccess }) => {
  const { data: user, isLoading, error } = useGetCurrentUserQuery();

  React.useEffect(() => {
    if (user && !isLoading && !error) {
      onSuccess?.(user);
    }
  }, [user, isLoading, error, onSuccess]);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <p>Error loading user data</p>;

  // Filter to only show email and name
  const filteredData = user
    ? Object.fromEntries(Object.entries(user).filter(([key]) => ['email', 'name'].includes(key)))
    : null;

  return filteredData ? (
    <ModelDisplay data={filteredData} fieldNames={fieldInfoDisplayUser} numCols={1} />
  ) : null;
};

UserModelDisplay.propTypes = {
  onSuccess: PropTypes.func,
};

export default UserModelDisplay;
