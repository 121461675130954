import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Button/Button'; // Add this import at the top of the file
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import {
  useGetGA4AccountsQuery,
  useGetGA4BigQueryLinkByIdQuery,
  useGetGA4PropertiesQuery,
} from 'services/rtkApi';

const GA4PropertyFormChecker = () => {
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [apiError, setApiError] = useState('');
  const [isRefetching, setIsRefetching] = useState(false);

  const { control, watch } = useForm();
  const watchedAccountId = watch('ga4Account');
  const watchedPropertyId = watch('ga4Property');

  const gcloud_project_id = useSelector((state) => state.user.gcloud_project_id);

  const {
    data: ga4Accounts,
    isLoading: isLoadingAccounts,
    error: ga4AccountsError,
  } = useGetGA4AccountsQuery(gcloud_project_id);

  const {
    data: ga4Properties,
    isLoading: isLoadingProperties,
    error: ga4PropertiesError,
    refetch: refetchProperties,
  } = useGetGA4PropertiesQuery(
    { accountId: selectedAccountId, gcloud_project_id },
    { skip: !selectedAccountId }
  );

  const { data: ga4BigQueryLink, isLoading: isLoadingBigQueryLink } =
    useGetGA4BigQueryLinkByIdQuery(
      { propertyId: selectedPropertyId, gcloud_project_id },
      { skip: !selectedPropertyId }
    );

  const getBigQueryExportLink = (accountId, propertyId) => {
    return `https://analytics.google.com/analytics/web/#/a${accountId}p${propertyId}/admin/streams`;
  };

  const bigQueryLinkInfo =
    ga4BigQueryLink && ga4BigQueryLink.length > 0 ? ga4BigQueryLink[0].dataset_location : null;

  useEffect(() => {
    if (watchedAccountId) {
      setSelectedAccountId(watchedAccountId);
      setSelectedPropertyId(null);
    }
  }, [watchedAccountId]);

  useEffect(() => {
    if (watchedPropertyId) {
      setSelectedPropertyId(watchedPropertyId);
    }
  }, [watchedPropertyId]);

  useEffect(() => {
    const error = ga4AccountsError || ga4PropertiesError;
    if (error && error.data) {
      const errorMessage = error.data.error;
      const truncatedMessage = errorMessage.split('[links')[0].trim();
      if (truncatedMessage.includes('Google Analytics Admin API has not been used in project')) {
        setApiError(truncatedMessage);
      }
    } else {
      setApiError('');
    }
  }, [ga4AccountsError, ga4PropertiesError]);

  useEffect(() => {
    if (selectedAccountId) {
      setIsRefetching(true);
      refetchProperties().finally(() => setIsRefetching(false));
    }
  }, [selectedAccountId, refetchProperties]);

  const isPropertiesLoading = isLoadingProperties || isRefetching;

  const ga4AccountOptions =
    ga4Accounts?.map((account) => ({
      value: account.account_id,
      label: account.display_name,
    })) || [];

  const ga4PropertyOptions =
    ga4Properties?.map((prop) => ({
      value: prop.property_id,
      label: prop.display_name,
    })) || [];

  // NBED THIs should actually be using a list endpoint. Our retrieve endpoitn returns mutliple values.

  return (
    <div>
      {apiError && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {apiError}
        </div>
      )}
      <FormSelect
        fieldKey="ga4Account"
        label="GA4 Account"
        control={control}
        errors={{}}
        options={ga4AccountOptions}
        isLoading={isLoadingAccounts}
        description="Select a GA4 account"
      />
      {selectedAccountId && (
        <div className="mt-4">
          <FormSelect
            fieldKey="ga4Property"
            label="GA4 Property"
            control={control}
            errors={{}}
            options={ga4PropertyOptions}
            isLoading={isPropertiesLoading}
            description="Select a GA4 property"
          />
        </div>
      )}

      {selectedPropertyId && (
        <div className="mt-4">
          {isLoadingProperties || isLoadingBigQueryLink ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : bigQueryLinkInfo ? (
            <div>
              <p>
                BigQuery link exists for property {selectedPropertyId} in region:{' '}
                <strong>{bigQueryLinkInfo}</strong>
              </p>
            </div>
          ) : (
            <div>
              <p className="text-sm">You need to create the BigQuery export for this property.</p>
              <Button
                href={getBigQueryExportLink(selectedAccountId, selectedPropertyId)}
                variant="primary"
                isOutline
                openInNewTab
              >
                Create BigQuery Export
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GA4PropertyFormChecker;
