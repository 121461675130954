import { useEffect, useState } from 'react';

/**
 * Custom hook for polling pipeline status with adaptive intervals
 *
 * Used on pages where we display typically the lastest pipeline run so that
 * we can examine it.
 *
 * @param {Object} params - Hook parameters
 * @param {function} params.refetchFn - Function to refetch pipeline data
 * @param {boolean} params.isRunInProgress - Whether pipeline is currently running
 * @param {number} params.initialInterval - Initial polling interval in ms (default: 30000)
 * @param {number} params.extendedInterval - Extended polling interval in ms (default: 60000)
 * @param {number} params.pollsBeforeExtended - Number of polls before switching to extended interval (default: 2)
 */
export const usePipelinePolling = ({
  refetchFn,
  isRunInProgress,
  initialInterval = 30000,
  extendedInterval = 60000,
  pollsBeforeExtended = 2,
}) => {
  const [pollCount, setPollCount] = useState(0);

  useEffect(() => {
    let intervalId;

    if (isRunInProgress) {
      // First polls at initial interval
      if (pollCount < pollsBeforeExtended) {
        intervalId = setInterval(() => {
          refetchFn();
          setPollCount((count) => count + 1);
        }, initialInterval);
      } else {
        // Switch to extended interval after initial polls
        intervalId = setInterval(refetchFn, extendedInterval);
      }
    } else {
      setPollCount(0);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [
    isRunInProgress,
    refetchFn,
    pollCount,
    initialInterval,
    extendedInterval,
    pollsBeforeExtended,
  ]);
};
