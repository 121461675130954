import { createSlice } from '@reduxjs/toolkit';

/**
 * Manages subscription and onboarding state
 * - Tracks when checkout is completed with a subscription
 * - Handles one-time onboarding message display post-subscription
 */
const pricingSlice = createSlice({
  name: 'pricing',
  initialState: {
    is_checkout_completed: false,
    is_onboarding_post_subscription: false,
  },
  reducers: {
    setSubscriptionComplete: (state) => {
      state.is_checkout_completed = true;
      state.is_onboarding_post_subscription = true;
    },
    clearSubscriptionComplete: (state) => {
      state.is_checkout_completed = false;
    },
    clearOnboardingPostSubscription: (state) => {
      state.is_onboarding_post_subscription = false;
    },
  },
});

export const {
  setSubscriptionComplete,
  clearSubscriptionComplete,
  clearOnboardingPostSubscription,
} = pricingSlice.actions;
export default pricingSlice.reducer;
