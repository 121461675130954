import { useCallback, useEffect, useState } from 'react';

import { usePaddlePrices } from 'hooks/paddle/usePaddlePrices';

import { isAddonPrice } from '../pricingUtils';

/**
 * Hook to manage pricing table upgrade state and logic
 * @param {Object} params
 * @param {Array} params.initialTiers - Initial pricing tiers
 * @param {Object} params.existingSubscription - Current subscription details
 * @param {Function} params.changeSubscriptionItem - Handler for plan changes
 */
export function usePricingTableUpgrade({
  initialTiers,
  existingSubscription,
  changeSubscriptionItem,
}) {
  // Get the initial billing cycle from the existing subscription
  useEffect(() => {
    if (existingSubscription?.items?.[0]?.price?.billing_cycle?.interval === 'year') {
      setBillingCycle('year');
    }
  }, [existingSubscription, setBillingCycle]);

  // Fetch the pricing tiers
  const {
    tiers,
    billingCycle,
    setBillingCycle,
    error,
    isLoading: isPricesLoading,
  } = usePaddlePrices(initialTiers);
  const [localTiers, setLocalTiers] = useState([]);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    if (tiers) {
      setLocalTiers(tiers);
    }
  }, [tiers]);

  const getBasePlanItem = useCallback((subscription) => {
    return subscription.items.find((item) => !isAddonPrice(item.price.id));
  }, []);

  const handlePlanChange = useCallback(
    async (tier, billingCycle) => {
      if (tier.priceDisplay[billingCycle] === 'Contact Us') {
        window.location.href = '/contact-sales';
        return;
      }

      setIsChanging(true);
      const basePlanItem = getBasePlanItem(existingSubscription);
      const isAnnualToMonthly =
        basePlanItem.price.billing_cycle.interval === 'year' && billingCycle === 'month';

      const currentPrice = parseFloat(basePlanItem.price.unit_price.amount);
      const newPrice = parseFloat(tier.priceNumeric[billingCycle]);
      const isDowngrade = newPrice < currentPrice;

      await changeSubscriptionItem({
        id: existingSubscription.id,
        old_price_id: basePlanItem.price.id,
        price_id: tier.priceId[billingCycle],
        quantity: 1,
        proration_billing_mode:
          isAnnualToMonthly || isDowngrade ? 'full_next_billing_period' : 'prorated_immediately',
      });
    },
    [existingSubscription, changeSubscriptionItem, getBasePlanItem]
  );

  const getButtonText = useCallback(
    (tier, billingCycle) => {
      if (tier.priceDisplay[billingCycle] === 'Contact Us') {
        return 'Contact Us';
      }

      const basePlanItem = getBasePlanItem(existingSubscription);
      return basePlanItem.price.id === tier.priceId[billingCycle] ? 'Current Plan' : 'Change Plan';
    },
    [existingSubscription, getBasePlanItem]
  );

  // Shared utility functions
  const utils = {
    getBillingCycleText: (billingCycle, priceDisplay) => {
      if (priceDisplay === 'Contact Us' || priceDisplay === 'Price unavailable') return '';
      return billingCycle === 'month' ? '/month' : '/year';
    },

    getTextSizeClass: (priceDisplay, isError) => {
      if (priceDisplay === 'Contact Us' || isError) return 'text-2xl';
      return 'text-4xl';
    },

    classNames: (...classes) => {
      return classes.filter(Boolean).join(' ');
    },
  };

  return {
    // State
    localTiers,
    billingCycle,
    isChanging,

    // Loading/Error states
    isPricesLoading,
    error,

    // Actions
    setBillingCycle,
    handlePlanChange,
    getButtonText,

    // Utility functions
    utils,
  };
}
