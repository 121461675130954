import { useCallback, useState } from 'react';

import { useUpdateDbtProjectFileListPropertyMutation } from 'services/rtkApi';

/**
 * Empty session property template.
 */
export const emptyUserTemplateStringNumber = {
  name: 'new_user_property_string_number',
  schema_template: 'string_number',
  propertyConditionBlock: {
    propertyConditions: [
      {
        conditionBlock: {
          andOr: 'and',
          conditions: [],
        },
      },
    ],
  },
};

export const emptyUserTemplateBool = {
  name: 'new_user_property_true_false',
  schema_template: 'bool',
  propertyConditionBlock: {
    propertyConditions: [
      {
        conditionBlock: {
          andOr: 'and',
          conditions: [],
        },
      },
    ],
  },
};

/**
 * Hook for managing GA4 user property templates and actions.
 *
 * @param {string} id - The project ID
 * @param {Array} initialUserProperties - The initial user properties
 * @param {Array} currentUserProperties - The current user properties from parent
 */
const useGA4UserPropertyTemplates = (id, initialUserProperties, currentUserProperties) => {
  const [updateDbtProjectFileListProperty] = useUpdateDbtProjectFileListPropertyMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [userProperties, setUserProperties] = useState(initialUserProperties);

  const userPropertyTemplates = [
    {
      title: 'Heavy User Last 30 Days',
      description: 'Identifies users who have heavily used our site in the last 30 days.',
      template: {
        name: 'heavy_user_last_30_days',
        schema_template: 'bool',
        when_to_update_property: {
          ignore_null: false,
        },
        propertyConditionBlock: {
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: '=',
                    value: 'page_view',
                  },
                ],
              },
              value: {
                on_condition_true: 1,
                agg: 'sum',
                window: 30,
              },
              compare: {
                operator: '>',
                value: 8,
              },
            },
          ],
        },
      },
    },
    {
      title: 'Customers who have purchased',
      description: 'Identifies users who have purchased from us.',
      template: {
        name: 'customers_who_have_purchased',
        schema_template: 'bool',
        when_to_update_property: {
          ignore_null: true,
          values_to_ignore: [false],
        },
        propertyConditionBlock: {
          _comment:
            "Get the last page_location, but only look at events where the last event matches the regex '/blog/'",
          propertyConditions: [
            {
              conditionBlock: {
                andOr: 'and',
                conditions: [
                  {
                    col: 'event_name',
                    operator: '=',
                    value: 'purchase',
                  },
                ],
              },
              compare: {
                operator: '>',
                value: '1',
              },
            },
          ],
        },
      },
    },
  ];

  const handleAddUserProperty = useCallback(
    (template) => {
      setIsUpdating(true);
      const updatedTemplate = { ...template };

      const getUniqueEventName = (baseName, existingEvents) => {
        let eventNumber = 0;
        let newName = baseName;

        const isNameTaken = (name) => existingEvents.some((event) => event.name === name);

        while (isNameTaken(newName)) {
          eventNumber += 1;
          newName = `${baseName}${eventNumber}`;
        }

        return newName;
      };

      updatedTemplate.name = getUniqueEventName(updatedTemplate.name, currentUserProperties);
      updatedTemplate.schema_template = template.schema_template;

      updateDbtProjectFileListProperty({
        id,
        property_name: 'derived_user_properties',
        operation: 'add',
        item: updatedTemplate,
      })
        .unwrap()
        .then(() => {
          setUserProperties([...userProperties, updatedTemplate]);
        })
        .catch((error) => {
          console.error('Failed to add conversion:', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [currentUserProperties, updateDbtProjectFileListProperty, id]
  );

  const handleDeleteUserProperty = useCallback(
    (indexToDelete) => {
      setIsUpdating(true);
      updateDbtProjectFileListProperty({
        id,
        property_name: 'derived_user_properties',
        operation: 'delete',
        index: indexToDelete,
      })
        .unwrap()
        .then(() => {
          setUserProperties(userProperties.filter((_, index) => index !== indexToDelete));
        })
        .catch((error) => {
          console.error('Failed to delete conversion:', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [userProperties, updateDbtProjectFileListProperty, id]
  );

  const emptyStateActions = userPropertyTemplates.map(({ title, description, icon, template }) => ({
    title,
    description,
    icon,
    action: () => handleAddUserProperty(template),
  }));

  return {
    emptyStateActions,
    handleAddUserProperty,
    handleDeleteUserProperty,
    isUpdating,
  };
};

export default useGA4UserPropertyTemplates;
