/**
 * Displays subscription details with addon management capabilities
 */
import { format } from 'date-fns';
import { useState } from 'react';

import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TextBubble from 'components/TextBubble/TextBubble';
import { useCancelPaddleSubscription } from 'hooks/paddle/useCancelPaddleSubscription';
import { useAddonQuantities } from 'pages/settings/subscription/hooks/useAddonQuantities';
import { useSubscriptionData } from 'pages/settings/subscription/hooks/useSubscriptionData';
import {
  useActivateSubscriptionMutation,
  useModifySubscriptionItemQuantityMutation,
} from 'services/rtkApi';

import { getTierAndAddonDetails } from '../pricingUtils';
import { PRICING_TIERS_DEV, PRICING_TIERS_PROD } from '../pricingV1Constants';

import ConfirmActionModal from './ConfirmActionModal';

/**
 * Controls for managing addon quantities
 * @param {Object} props
 * @param {number} props.currentQuantity - Current number of seats
 * @param {Function} props.onQuantityChange - Callback when quantity changes
 * @param {boolean} props.isDisabled - Whether controls should be disabled
 */
const AddonControls = ({ currentQuantity = 0, onQuantityChange, isDisabled }) => {
  const [quantity, setQuantity] = useState(currentQuantity);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Local state changes only
  const handleChange = (newQuantity) => {
    const validQuantity = Math.max(0, newQuantity);
    setQuantity(validQuantity);
    setIsChanged(validQuantity !== currentQuantity);
  };

  // Trigger parent handler for modal & API changes
  const handleSave = () => {
    if (isChanged) {
      onQuantityChange(quantity);
    }
  };

  const additionalSeats = quantity - currentQuantity;
  const buttonText = isChanged ? `${additionalSeats > 0 ? 'Add' : 'Remove'} Seats` : 'No Changes';

  if (isDisabled) {
    return <div className="text-sm text-gray-500">Add-ons can&apos;t be added.</div>;
  }

  return (
    <div className="flex shrink-0 items-center gap-x-2">
      <Button
        variant="primary"
        onClick={handleSave}
        disabled={!isChanged || isLoading}
        className="mr-4"
      >
        {buttonText}
      </Button>
      <Button
        onClick={() => handleChange(quantity - 1)}
        disabled={quantity === 0}
        variant="secondary"
        className="!px-2 !py-1 min-w-[32px] text-gray-500"
      >
        -
      </Button>
      <Button
        onClick={() => handleChange(quantity + 1)}
        variant="secondary"
        className="!px-2 !py-1 min-w-[32px] text-gray-500"
      >
        +
      </Button>
      <div className="border border-gray-300 rounded px-3 py-1 min-w-[40px] text-center">
        <span className="text-sm text-gray-900">{quantity}</span>
      </div>
    </div>
  );
};

const isAddonPrice = (priceId) => {
  const pricingTiers = window.IS_DEV ? PRICING_TIERS_DEV : PRICING_TIERS_PROD;

  const isAddon = pricingTiers.some((tier) => {
    return tier.addons?.some((addon) => {
      const matches = addon.priceId.month === priceId || addon.priceId.year === priceId;
      return matches;
    });
  });

  return isAddon;
};

/**
 * Handles the layout of the subscription summary item.
 * @param {*} param0
 * @returns
 */
const SubscriptionSummaryItem = ({
  name,
  price_amount,
  currency_code,
  access_until,
  is_currently_active,
  quantity,
}) => (
  <div className="flex justify-between items-center py-2">
    <div>
      <p className="text-sm text-gray-600 pb-0">
        {name} {quantity > 1 && `(${quantity})`}
        {is_currently_active ? (
          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
            Active
          </span>
        ) : (
          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
            Cancelled - Due to expire
          </span>
        )}
      </p>
      {access_until && (
        <p className="text-xs text-gray-500">
          Access until: {format(new Date(access_until), 'MMM dd, yyyy')}
        </p>
      )}
    </div>
    {is_currently_active && (
      <span className="text-sm text-gray-600">
        {new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: currency_code,
        }).format(price_amount * quantity)}
      </span>
    )}
  </div>
);

// Add this helper function at the top level
const getAllAvailableAddons = (currentTier) => {
  const pricingTiers = window.IS_DEV ? PRICING_TIERS_DEV : PRICING_TIERS_PROD;

  const tier = pricingTiers.find(
    (t) => t.priceId?.month === currentTier?.price?.id || t.priceId?.year === currentTier?.price?.id
  );
  return tier?.addons || [];
};

// Add this helper at the top level
const getEarliestAccessDate = (activePlans) => {
  if (!activePlans?.length) return null;

  return activePlans.reduce((earliest, plan) => {
    const accessDate = new Date(plan.access_until);
    return earliest ? (accessDate < earliest ? accessDate : earliest) : accessDate;
  }, null);
};

// Add this helper function
const areAllPlansInactive = (activePlans) => {
  return activePlans?.every((plan) => !plan.is_currently_active) ?? false;
};

export default function SubscriptionBox({
  subscription,
  activePlans,
  isLoadingActivePlans,
  buttons,
  disabled,
  onAddonChange,
  refetch,
  onChangeComplete,
}) {
  const {
    baseItem,
    addonItems,
    billingInterval,
    total: currentTotal,
    nextBillingDate,
    trialEndDate,
  } = useSubscriptionData(subscription);
  // console.log('subscription', subscription);
  // console.log('baseItem', baseItem);
  // console.log('addonItems', addonItems);
  // console.log('billingInterval', billingInterval);
  // console.log('activePlans', activePlans);
  // console.log('trialEndDate', trialEndDate);

  const isCancelled = subscription.status === 'canceled';
  const isTrialing = subscription.status === 'trialing';

  const [modifyItemQuantity] = useModifySubscriptionItemQuantityMutation();
  const { addonChanges, handleQuantityChange, calculateNewTotal, hasChanges, resetChanges } =
    useAddonQuantities(subscription);

  const newTotal = hasChanges ? calculateNewTotal(currentTotal) : currentTotal;

  const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);

  // Add new state for modals
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null,
  });

  const { removeSubscriptionItems } = useCancelPaddleSubscription();
  const [activateSubscription] = useActivateSubscriptionMutation();

  // Handle addon quantity changes
  const handleAddonQuantityChange = (priceId, newQuantity, currentQuantity) => {
    const diff = newQuantity - currentQuantity;
    const addon = getTierAndAddonDetails(priceId)?.addon;

    if (diff !== 0) {
      setModalConfig({
        isOpen: true,
        title: diff > 0 ? 'Add Seats' : 'Remove Seats',
        message: `Are you sure you want to ${diff > 0 ? 'add' : 'remove'} ${Math.abs(diff)} ${
          addon?.name
        } seats?`,
        onConfirm: async () => {
          try {
            setIsUpdatingSubscription(true);
            await modifyItemQuantity({
              subscriptionId: subscription.id,
              priceId,
              quantity: newQuantity,
            });
            handleQuantityChange(priceId, newQuantity, currentQuantity);
            await refetch();
            onChangeComplete?.();
          } finally {
            setIsUpdatingSubscription(false);
            setModalConfig({ isOpen: false });
          }
        },
      });
    }
  };

  // Handle subscription cancellation
  const handleCancelSubscription = async (item) => {
    setModalConfig({
      isOpen: true,
      title: 'Cancel Subscription',
      message:
        'Are you sure you want to cancel your subscription? You will lose access at the end of your billing period.',
      onConfirm: async () => {
        try {
          setIsUpdatingSubscription(true);
          await removeSubscriptionItems({
            subscriptionId: subscription.id,
            itemId: item.id,
          });
          await refetch();
        } finally {
          setIsUpdatingSubscription(false);
          setModalConfig({ isOpen: false });
        }
      },
    });
  };

  // Handle subscription activation
  const handleActivateSubscription = async (subscription) => {
    setModalConfig({
      isOpen: true,
      title: 'Activate Subscription',
      message:
        'Are you sure you want to activate your subscription? You will be billed immediately.',
      onConfirm: async () => {
        try {
          setIsUpdatingSubscription(true);
          await activateSubscription(subscription.id);
          await refetch();
        } finally {
          setIsUpdatingSubscription(false);
          setModalConfig({ isOpen: false });
        }
      },
    });
  };

  // Get the earliest access date
  const accessUntilDate = getEarliestAccessDate(activePlans);

  const isAllPlansInactive = areAllPlansInactive(activePlans);

  if (isUpdatingSubscription) {
    return <LoadingSpinner text="Updating subscription details" />;
  }

  return (
    <>
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        {/* Header */}
        <div className="px-4 py-5 sm:px-6 border-b border-gray-100">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-base font-semibold text-gray-900">Subscription</h3>
              <p className="mt-1 text-sm text-gray-500 pb-0">
                {isCancelled
                  ? `Your plan is cancelled. You will lose access on ${format(
                      accessUntilDate,
                      'MMM dd, yyyy'
                    )}`
                  : isTrialing
                  ? `Free Trial: Trial will end on ${format(
                      new Date(trialEndDate),
                      'MMM dd, yyyy'
                    )}`
                  : `Next billing date: ${format(new Date(nextBillingDate), 'MMM dd, yyyy')}`}
              </p>
            </div>
            <div className="text-right">
              <div>
                <span className="text-xl font-bold">
                  £{(isCancelled ? 0 : currentTotal / 100).toFixed(2)}
                </span>
                <span className="text-sm text-gray-500">/{billingInterval}</span>
              </div>
              {hasChanges && (
                <div className="mt-1 text-sm text-gray-500">
                  New total: <span className="font-semibold">£{(newTotal / 100).toFixed(2)}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Summary Section with divider */}
        <div className="px-4 py-5 sm:px-6 border-b border-gray-100 bg-gray-50">
          <h4 className="text-sm font-bold text-gray-900">Subscription Summary</h4>
        </div>

        <div className="px-4 py-5 sm:px-6 border-b border-gray-100">
          {isLoadingActivePlans ? (
            <LoadingSpinner text="Loading subscription details..." />
          ) : (
            activePlans?.map((plan) => (
              <SubscriptionSummaryItem
                key={plan.price_id}
                name={plan.name}
                price_amount={plan.price_amount}
                currency_code={plan.currency_code}
                access_until={plan.access_until}
                is_currently_active={plan.is_currently_active}
                quantity={plan.quantity}
              />
            ))
          )}
        </div>

        {/* New Change Subscription heading */}
        <div className="px-4 py-5 sm:px-6 border-b border-gray-100 bg-gray-50">
          <h4 className="text-sm font-bold text-gray-700">Change Subscription</h4>
        </div>

        {/* Base Plan Section */}
        <div className="border-b border-gray-100">
          {baseItem && (
            <div className="relative flex flex-col gap-y-2 px-4 py-5 sm:px-6">
              <div className="flex justify-between items-center">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900 pb-0">
                    {baseItem.price?.name || 'Unknown'}
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 pb-0">
                    {baseItem.product?.name || 'Unknown'}
                  </p>
                </div>
                <div className="flex shrink-0 items-center gap-x-2">
                  {buttons.map((button) => {
                    if (isTrialing && button.label === 'Change Plan') {
                      return (
                        <Button
                          key="activate-trial"
                          onClick={() => handleActivateSubscription(subscription)}
                          variant="primary"
                        >
                          Activate Subscription
                        </Button>
                      );
                    }

                    return (
                      <Button
                        key={`${baseItem.id}-${button.label}`}
                        onClick={() => button.onClick(baseItem, subscription)}
                        disabled={disabled}
                        variant={button.variant === 'danger' ? 'danger' : 'primary'}
                        isOutline={button.isOutline}
                      >
                        {button.label}
                      </Button>
                    );
                  })}
                </div>
              </div>
              {isTrialing && (
                <TextBubble
                  color="blue"
                  text={`Plan changes will be available after your trial ends on ${format(
                    new Date(trialEndDate),
                    'MMM dd, yyyy'
                  )}`}
                />
              )}
            </div>
          )}
        </div>

        {/* Addons Section */}
        <div className="divide-y divide-gray-100">
          {getAllAvailableAddons(baseItem).map((availableAddon) => {
            // Find if user already has this addon
            const existingItem = addonItems.find(
              (item) => item.price.id === availableAddon.priceId[billingInterval]
            );
            const currentQuantity = existingItem?.quantity || 0;
            const newQuantity = addonChanges[availableAddon.priceId[billingInterval]];
            const hasChange = newQuantity !== undefined && newQuantity !== currentQuantity;

            return (
              <div key={availableAddon.priceId[billingInterval]}>
                <div className="relative flex flex-col gap-y-2 px-4 py-5 sm:px-6">
                  <div className="flex justify-between items-center">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900 pb-0">
                        {availableAddon.name} {currentQuantity > 0 && `(${currentQuantity})`}
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500 pb-0">
                        {availableAddon.description}
                      </p>
                    </div>

                    <AddonControls
                      currentQuantity={currentQuantity}
                      onQuantityChange={(quantity) =>
                        handleAddonQuantityChange(
                          availableAddon.priceId[billingInterval],
                          quantity,
                          currentQuantity
                        )
                      }
                      isDisabled={isAllPlansInactive}
                    />
                  </div>

                  {hasChange && (
                    <TextBubble
                      color={newQuantity > currentQuantity ? 'blue' : 'grey'}
                      text={
                        newQuantity > currentQuantity
                          ? `You're adding ${newQuantity - currentQuantity} of ${
                              availableAddon.name
                            }. You will be billed a prorated amount for the remainder of this billing period.`
                          : `${currentQuantity - newQuantity} of ${
                              availableAddon.name
                            } will be removed. You won't be billed for them again. You will still have access to them until the end of the month.`
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <ConfirmActionModal
        isOpen={modalConfig.isOpen}
        onClose={() => setModalConfig({ isOpen: false })}
        onConfirm={modalConfig.onConfirm}
        title={modalConfig.title}
        message={modalConfig.message}
        isLoading={isUpdatingSubscription}
      />
    </>
  );
}
