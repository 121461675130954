import { Bars3Icon } from '@heroicons/react/24/outline';

import TenantSwitcher from 'components/TenantSwitcher/TenantSwitcher';
import { useGetAccountsQuery, useGetCurrentUserQuery } from 'services/rtkApi';

const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TopBar({ setSidebarOpen }) {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: accountsData, isLoading } = useGetAccountsQuery();

  const shouldShowTenantSwitcher = currentUser?.is_superuser || accountsData?.count > 1;

  return (
    <div className="sticky top-0 z-40 flex shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        {shouldShowTenantSwitcher && <TenantSwitcher />}
        <div className="flex items-center gap-x-4 lg:gap-x-6">{/* <TimeTr acker /> */}</div>
      </div>
    </div>
  );
}
