import Button from 'components/Button/Button';
import SourceGA4BigQueryFormSection from 'pages/models/source_ga4_bigquery/_components/SourceGA4BigQueryFormSection';

/**
 * Page descriptions and documentation links for GA4 BigQuery form pages
 * @type {Object.<string, {title: string, description: string[], docLink: string}>}
 */
const GA4_PAGE_DESCRIPTIONS = {
  main: {
    title: 'Getting Started',
    description: [
      "Let's get started! Pick the GA4 property you want to add.",
      "You can add multiple properties and we'll roll them all up into one dataset. You'll typically only want to do this if you're using multiple properties for the same site.",
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-required-setup/adding-ga4-to-your-pipeline',
  },

  ecommerce: {
    title: 'What is it?',
    description: [
      'Are you an ecommerce site? If so you can use this section to extract ecommerce data from your GA4 data.',
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/ecommerce',
  },

  advanced: {
    title: 'Advanced Settings',
    description: [
      'Configure advanced settings for your GA4 BigQuery pipeline.',
      'These settings allow you to fine-tune how your GA4 data is processed and stored.',
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/advanced-configuration',
  },

  columnsToUnnest: {
    title: 'Event Parameters',
    description: [
      'There is a lot of useful data in GA4. We extract most of it automatically so you can use it.',
      "But we won't pull out custom parameters you use (and very occasionally you might want a default parameter we don't pull out).",
      "Add any here and if you're not sure don't worry, you can come back and add them later.",
    ],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/extracting-extra-data-for-ga4',
  },
  conversions: {
    title: 'Conversions',
    description: ['Configure your conversions here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/conversion-events',
  },
  spamFilter: {
    title: 'Spam Filter',
    description: ['Configure your spam filter here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/spam-filter',
  },
  channelGroupings: {
    title: 'Channel Groupings',
    description: ['Configure your channel groupings here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/channel-groupings',
  },
  sessionProperties: {
    title: 'Session Properties',
    description: ['Configure your session properties here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/session-properties',
  },
  userProperties: {
    title: 'User Properties',
    description: ['Configure your user properties here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/user-properties',
  },
  advancedBQ: {
    title: 'Advanced BQ',
    description: ['Configure your advanced BQ settings here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/advanced-bq',
  },
  attribution: {
    title: 'Attribution',
    description: ['Configure your attribution settings here.'],
    docLink:
      'https://www.pipedout.com/documentation/setup-instructions/ga4-optional-setup/attribution',
  },
};

/**
 * Renders a standardized description section for GA4 BigQuery pages
 * @param {Object} props
 * @param {string} props.pageKey - Key to lookup in GA4_PAGE_DESCRIPTIONS
 */
const SourceGA4BigQueryPageDescription = ({ pageKey }) => {
  const pageData = GA4_PAGE_DESCRIPTIONS[pageKey];

  if (!pageData) return null;

  return (
    <SourceGA4BigQueryFormSection isBottomBorder>
      <div className="text-gray-600 text-sm w-full">
        <div className="flex justify-between items-center mb-4 w-full">
          <div>
            {pageData.title && (
              <h3 className="mb-2">
                <strong>{pageData.title}</strong>
              </h3>
            )}
            {pageData.description.map((desc, index) => (
              <p key={index}>{desc}</p>
            ))}
          </div>
          <Button href={pageData.docLink} variant="primary" isOutline openInNewTab>
            Documentation
          </Button>
        </div>
      </div>
    </SourceGA4BigQueryFormSection>
  );
};

export default SourceGA4BigQueryPageDescription;
