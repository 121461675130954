import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import ModalAction from 'components/ModalAction/ModalAction';
import {
  useDeleteGA4PipelineTablesMutation,
  useGetCurrentUserQuery,
  useGetLatestPipelineRunQuery,
  useGetPipelineByIdQuery,
  useGetSourceGA4BigQueriesQuery,
  useRunPipelineMutation,
  useUpdatePipelineDbtProjectMutation,
} from 'services/rtkApi';

/**
 * Renders a button strip for pipeline actions, including "Manually Run Pipeline" and "Update dbt Project".
 * @param {Object} props - Component props
 * @param {number} props.pipelineId - ID of the pipeline
 * @param {boolean} props.isDisabled - Whether the action should be disabled
 * @param {string} props.className - Additional CSS classes for the container
 */
const PipelineActionStrip = ({ pipelineId, isDisabled, className }) => {
  const [runPipeline, { isLoading }] = useRunPipelineMutation();
  const [updatePipelineDbtProject, { isLoading: isUpdatingDbtProject }] =
    useUpdatePipelineDbtProjectMutation();
  const { data: user } = useGetCurrentUserQuery();

  // Get GA4 sources and if there are none, disable the run pipeline button
  const {
    data: ga4Sources,
    error: ga4Error,
    isLoading: isLoadingGA4Sources,
  } = useGetSourceGA4BigQueriesQuery(pipelineId ? { pipeline: pipelineId } : {});
  const hasNoGA4Sources =
    !isLoadingGA4Sources && (!ga4Sources?.results || ga4Sources.results.length === 0);

  // Check if setup is complete
  // We can only handle one GA4 per pipeline.
  const isSetupComplete = ga4Sources?.results?.[0]?.current_pipeline_build_status === 'complete';

  // Get the latest pipeline run to see if it's already running
  const { data: latestPipelineRun, isLoading: isLoadingLatestRun } =
    useGetLatestPipelineRunQuery(pipelineId);
  const isPipelineRunning = latestPipelineRun?.is_run_in_progress;

  const handleRunPipeline = async (fullRefresh = false) => {
    try {
      await runPipeline({ id: pipelineId, run_type: 'ga4', full_refresh: fullRefresh });
      // Handle success (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Failed to run pipeline:', error);
    }
  };

  const handleUpdateDbtProject = async () => {
    try {
      await updatePipelineDbtProject(pipelineId).unwrap();
      // Handle successful update (e.g., show a success message)
    } catch (error) {
      console.error('Failed to update dbt project:', error);
    }
  };

  const { id } = useParams();
  const { data: pipeline, isLoading: isLoadingPipeline } = useGetPipelineByIdQuery(id);

  const getBigQueryUrl = () => {
    if (!pipeline?.gcloud_project_id || !pipeline?.bq_output_dataset) return null;
    return `https://console.cloud.google.com/bigquery?ws=!1m4!1m3!3m2!1s${pipeline.gcloud_project_id}!2s${pipeline.bq_output_dataset}`;
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteGA4Tables, { isLoading: isDeleting }] = useDeleteGA4PipelineTablesMutation();

  const handleDeleteGA4Tables = async (include_copied_events) => {
    try {
      await deleteGA4Tables({
        id: pipelineId,
        include_copied_events,
      });
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete GA4 tables:', error);
    }
  };

  const [isLookerModalOpen, setIsLookerModalOpen] = useState(false);

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="flex items-center space-x-4">
        <h4 className="text-sm font-medium w-24">Process</h4>
        <div className="flex space-x-4">
          <Button
            onClick={() => handleRunPipeline(false)}
            loading={isLoading}
            disabled={isDisabled || isLoading || isPipelineRunning || hasNoGA4Sources}
            variant="primary"
            isOutline
          >
            {isPipelineRunning ? 'Pipeline Running...' : 'Manually Run Pipeline'}
          </Button>
          {user?.is_superuser && (
            <Button
              onClick={() => handleRunPipeline(true)}
              loading={isLoading}
              disabled={isDisabled || isLoading || isPipelineRunning || hasNoGA4Sources}
              variant="primary"
              isOutline
            >
              {isPipelineRunning ? 'Pipeline Running...' : 'Manually Run Pipeline - Full Refresh'}
            </Button>
          )}
          {user?.is_superuser && (
            <Button
              onClick={handleUpdateDbtProject}
              loading={isUpdatingDbtProject}
              disabled={isUpdatingDbtProject}
              variant="primary"
              isOutline
            >
              Update dbt Project
            </Button>
          )}
          <Button onClick={() => setIsDeleteModalOpen(true)} variant="danger" isOutline>
            Delete GA4 Tables
          </Button>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <h3 className="text-sm font-medium w-24">View</h3>
        <div className="flex space-x-4">
          <Button
            href={getBigQueryUrl()}
            disabled={isLoading || !getBigQueryUrl()}
            variant="primary"
            openInNewTab
            isOutline
          >
            View in BigQuery
          </Button>

          <Button
            onClick={() => setIsLookerModalOpen(true)}
            variant="primary"
            disabled={!isSetupComplete}
            isOutline
          >
            Looker Studio Connector
          </Button>

          {/* <Button href="#" variant="primary" openInNewTab disabled={!isSetupComplete} isOutline>
            Report Templates
          </Button> */}
        </div>
      </div>

      <ModalAction
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete GA4 Tables for Pipeline"
        description="Choose how you want to delete the GA4 tables. Including events will delete all copied event tables from the raw dataset."
        actionType="delete"
        buttons={[
          {
            text: 'Delete Tables (Including Events)',
            onClick: () => handleDeleteGA4Tables(true),
            variant: 'danger',
            loading: isDeleting,
          },
          {
            text: 'Delete Tables (Excluding Events)',
            onClick: () => handleDeleteGA4Tables(false),
            variant: 'primary',
            loading: isDeleting,
          },
          {
            text: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            variant: 'secondary',
            disabled: isDeleting,
          },
        ]}
      />

      <ModalAction
        isOpen={isLookerModalOpen}
        onClose={() => setIsLookerModalOpen(false)}
        title="Looker Studio Connector Details"
        actionType="create"
        description={
          <div className="space-y-4 ">
            <ul className="list-disc pl-8 ">
              <li>
                <strong>Link: </strong>
                <a
                  href="https://datastudio.google.com/datasources/create?connectorId=AKfycbwmJ61PQUIim709LqiS27ekmiQuWEj2Tk9eQu4uxZY0KRE3PdH8fSmFjBg7XAc913rwkw"
                  className="text-deep-sea-green-900 underline hover:text-deep-sea-green-800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Create Looker Studio Data Source
                </a>
              </li>
              <li>
                <strong>Project: </strong>
                {pipeline?.gcloud_project_id}
              </li>
              <li>
                <strong>Dataset: </strong>
                {pipeline?.bq_output_dataset}
              </li>
            </ul>
          </div>
        }
        buttons={[
          {
            text: 'Close',
            onClick: () => setIsLookerModalOpen(false),
            variant: 'secondary',
          },
        ]}
      />
    </div>
  );
};

PipelineActionStrip.propTypes = {
  pipelineId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

PipelineActionStrip.defaultProps = {
  isDisabled: false,
  className: '',
};

export default PipelineActionStrip;
