import dayjs from 'dayjs';
import { isEmpty, isEqual } from 'lodash';

export function unformattedToTitleCase(str) {
  if (typeof str !== 'string') return '';
  if (str !== str.toLowerCase()) return str; // Return original if not all lowercase

  // Replace snake_case and camelCase with space-separated words
  const result = str
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/([a-z])([A-Z])/g, '$1 $2'); // Add space before capital letters

  // Capitalize the first letter of each word
  return result.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// // Helper function to unwrap the type if it's optional
// export const getZodInnerType = (schema) => {
//   if (schema instanceof z.ZodOptional) {
//     // eslint-disable-next-line no-underscore-dangle
//     return schema._def.innerType;
//   }
//   return schema;
// };

// Utility function to construct query string
export function createQueryString(params) {
  const query = Object.entries(params)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return query ? `?${query}` : '';
}

/**
 * Capitalizes the first letter of a string.
 * @param {*} s
 * @returns
 */
export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * This validates the date string is in the right format and is a valid date.
 *
 * We need this because when we are pulling down dates from django they come as strings
 * and then in the back and forth between the different forms and date elements
 * we sometimes have to deal with them as strings.
 *
 * @param {*} dateString
 * @returns
 */
export const dateStringValidator = (dateString) => {
  // Regular expression for YYYY-MM-DD format
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateString) return false; // or return true if the field is optional

  // Check if the string matches the date format
  if (!regex.test(dateString)) return false;

  // Additional check to ensure the date is valid
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};

/**
 * This takes a capacity URL format and replaces the year and month
 * with the current year and month.
 *
 * @param {*} urlPattern
 * @returns
 */
export const createCapacityURL = (
  urlPattern,
  year = dayjs().format('YYYY'),
  month = dayjs().format('MM')
) => {
  // Ensure month is always two digits
  const formattedMonth = month.toString().padStart(2, '0');
  return urlPattern.replace(':year', year).replace(':month', formattedMonth);
};

/**
 * Constructs a URL by replacing placeholders with provided parameters.
 *
 * @param {string} urlPattern - The URL pattern containing placeholders in the format `:key`.
 * @param {Object} params - An object containing key-value pairs to replace in the URL pattern.
 * @returns {string} - The constructed URL with placeholders replaced by parameter values.
 */
export const createURLWithParams = (urlPattern, params) => {
  return Object.entries(params).reduce((url, [key, value]) => {
    const formattedValue = value.toString().padStart(2, '0'); // Ensure value is always two digits
    return url.replace(`:${key}`, formattedValue);
  }, urlPattern);
};

// export function getColorForPercentage(percentage) {
//   let hue;
//   const normalizedPercentage = percentage / 100;

//   if (normalizedPercentage === 1) {
//     hue = 120; // Hue for green
//   } else if (normalizedPercentage <= 0 || normalizedPercentage >= 2) {
//     hue = 0; // Hue for red
//   } else {
//     hue = (1 - normalizedPercentage) * 120;
//   }

//   const color = `hsl(${hue}, 100%, 40%)`; // Regular color
//   const backgroundColor = `hsl(${hue}, 100%, 85%)`; // Lighter background color

//   return { color, backgroundColor };
// }
export function getColorForPercentage(percentage) {
  let r;
  let g;
  let b;
  const normalizedPercentage = Math.max(0, Math.min(percentage / 100, 2)); // Clamp between 0 and 2

  if (normalizedPercentage <= 1) {
    // Transition from dark grey to darker green (0% to 100%)
    // Dark Grey: rgb(90, 90, 90) to Dark Green: rgb(25, 70, 0)
    r = 90 + (25 - 90) * normalizedPercentage;
    g = 90 + (70 - 90) * normalizedPercentage;
    b = 90 + (0 - 90) * normalizedPercentage;
  } else {
    // Transition from darker green to dark red (100% to 200%)
    // Dark Green: rgb(25, 70, 0) to Dark Red: rgb(180, 0, 0)
    r = 25 + (180 - 25) * (normalizedPercentage - 1);
    g = 70 * (2 - normalizedPercentage); // Diminish green
    b = 0; // Keep blue at 0
  }

  const color = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
  let backgroundR;
  let backgroundG;
  let backgroundB;

  // Background color adjustment
  if (normalizedPercentage <= 1) {
    // Transition from very light grey to lighter green (0% to 100%)
    // Very Light Grey: rgb(230, 230, 230) to Light Green: rgb(187, 229, 156)
    backgroundR = 230 + (187 - 230) * normalizedPercentage;
    backgroundG = 230 + (229 - 230) * normalizedPercentage;
    backgroundB = 230 + (156 - 230) * normalizedPercentage;
  } else {
    // Transition from lighter green to lighter red (100% to 200%)
    // Light Green: rgb(187, 229, 156) to Light Red: rgb(255, 100, 100)
    backgroundR = 187 + (255 - 187) * (normalizedPercentage - 1);
    backgroundG = 229 + (100 - 229) * (normalizedPercentage - 1);
    backgroundB = 156 + (100 - 156) * (normalizedPercentage - 1);
  }

  const backgroundColor = `rgb(${Math.round(backgroundR)}, ${Math.round(backgroundG)}, ${Math.round(
    backgroundB
  )})`;

  return { color, backgroundColor };
}

export function getColorForPercentageFromBase(percentage, baseColor) {
  const [baseR, baseG, baseB] = baseColor.match(/\d+/g).map(Number); // Extract RGB components
  let r;
  let g;
  let b;
  let textR;
  let textG;
  let textB;
  let backgroundR;
  let backgroundG;
  let backgroundB;
  const normalizedPercentage = Math.max(0, Math.min(percentage / 100, 2)); // Clamp between 0 and 2

  if (normalizedPercentage <= 1) {
    // Base color remains unchanged for the main color
    [r, g, b] = [baseR, baseG, baseB];
    // Generate a lighter background color
    [backgroundR, backgroundG, backgroundB] = [
      baseR + (255 - baseR) * 0.3,
      baseG + (255 - baseG) * 0.3,
      baseB + (255 - baseB) * 0.3,
    ];
    // Text color starts as black
    [textR, textG, textB] = [0, 0, 0];
  } else {
    // Transition to red for the main color
    const transitionFactor = normalizedPercentage - 1; // Directly use the factor over 100%
    r = baseR + (204 - baseR) * transitionFactor;
    g = baseG * (2 - normalizedPercentage); // Diminish green
    b = baseB * (2 - normalizedPercentage); // Diminish blue
    // Text color transitions towards white after 100%
    textR = 255 * transitionFactor;
    textG = 255 * transitionFactor;
    textB = 255 * transitionFactor;
    // Background also transitions towards red, but remains lighter than the main color
    backgroundR = baseR + (255 - baseR) * transitionFactor * 0.5;
    backgroundG = baseG * (2 - normalizedPercentage) * 0.5;
    backgroundB = baseB * (2 - normalizedPercentage) * 0.5;
  }

  const color = `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
  const textColor = `rgb(${Math.round(textR)}, ${Math.round(textG)}, ${Math.round(textB)})`;
  const backgroundColor = `rgb(${Math.round(backgroundR)}, ${Math.round(backgroundG)}, ${Math.round(
    backgroundB
  )})`;

  return { color, textColor, backgroundColor };
}

export const getMonthFromStartDate = (date_str) => {
  return dayjs(date_str).format('YYYY - MMM');
};

/**
 * This function allows you to recursively apply a function to an object.
 *
 * It only applies the function to elements in a dict which pass the condition
 * clause.
 * @param  {list} func        The function
 * @param  {value} element    The element being passed through
 */
export const applyFunctionRecursivelyToDicts = (
  func,
  element,
  listCondition = false,
  condition = false,
  ...args
) => {
  let updatedElement;
  if (Array.isArray(element)) {
    // If we have an array, loop through and apply the function
    // to each value in the list.
    updatedElement = element.map((listEle) => {
      if (listCondition(listEle)) {
        return func(listEle, ...args);
      }
      return applyFunctionRecursivelyToDicts(func, listEle, listCondition, condition, ...args);
    });
    return updatedElement;
    // eslint-disable-next-line no-else-return
  } else if (typeof element === 'object' && element !== null) {
    // If we have an object, loop through and apply the function to each
    // value unless the condition is true
    updatedElement = Object.entries(element).reduce((acc, [key, val]) => {
      if (condition && condition(key, val)) {
        acc[key] = func(val, ...args);
      } else {
        acc[key] = applyFunctionRecursivelyToDicts(func, val, listCondition, condition, ...args);
      }

      return acc;
    }, {});

    return updatedElement;
  }
  return element;
};

export const handleDjangoDecimalFields = (response, meta, arg) => {
  // These are all of django's decimal fields
  const decimalFields = ['budget_billed', 'budget_value_price', 'budget_available'];

  // const isDecimalField = (key) => decimalFields.includes(key);

  const isDecimalField = (key) => {
    const result = decimalFields.includes(key);
    return result;
  };

  const convertToNumber = (value) => {
    if (typeof value === 'string') {
      return Number(value);
    }
    return value;
  };

  // Recursively loop through and convert decimal fields.
  const transformData = (data) => {
    return applyFunctionRecursivelyToDicts(convertToNumber, data, () => false, isDecimalField);
  };
  const output = transformData(response);

  return output;
};

/**
 * This function extracts error messages from the server returned within
 * an axios error response.
 *
 * @param {*} errorResponse
 * @returns
 */
export const extractErrorFromAxios = (errorResponse) => {
  if (typeof errorResponse !== 'object' || errorResponse === null) {
    return 'Invalid error response';
  }

  const errorMessages = [];

  // Check for 'message' key
  // if (errorResponse.message) {
  //   errorMessages.push(errorResponse.message);
  // }

  // Check for nested error messages in the 'response' object
  if (errorResponse.response && typeof errorResponse.response.data === 'object') {
    const responseData = errorResponse.response.data;

    for (const key in responseData) {
      if (Array.isArray(responseData[key])) {
        errorMessages.push(...responseData[key]);
      } else if (typeof responseData[key] === 'string') {
        errorMessages.push(responseData[key]);
      }
    }
  }

  return errorMessages.join('\n');
};

/**
 * Returns full paths of all changed keys between two objects.
 *
 * @param {Object} obj1 - The first object to compare
 * @param {Object} obj2 - The second object to compare
 * @param {Array} prefix - The current path prefix (used for recursion)
 * @returns {Array} An array of objects containing the full path and value changes
 */
export const getChangedKeyPaths = (obj1, obj2, prefix = []) => {
  const changedKeys = [];

  const compareObjects = (o1, o2, currentPrefix) => {
    for (const key in o2) {
      if (Object.prototype.hasOwnProperty.call(o2, key)) {
        const fullKey = [...currentPrefix, key];

        if (!(key in o1)) {
          // New key added, explore its nested structure
          if (typeof o2[key] === 'object' && o2[key] !== null) {
            compareObjects({}, o2[key], fullKey);
          } else {
            changedKeys.push({
              path: fullKey,
              previousValue: undefined,
              newValue: o2[key],
            });
          }
        } else if (typeof o2[key] === 'object' && o2[key] !== null) {
          // Recursively compare nested objects
          compareObjects(o1[key] || {}, o2[key], fullKey);
        } else if (!isEqual(o1[key], o2[key])) {
          // Value changed
          changedKeys.push({
            path: fullKey,
            previousValue: o1[key],
            newValue: o2[key],
          });
        }
      }
    }

    // Check for removed keys
    for (const key in o1) {
      if (Object.prototype.hasOwnProperty.call(o1, key) && !(key in o2)) {
        changedKeys.push({
          path: [...currentPrefix, key],
          previousValue: o1[key],
          newValue: undefined,
        });
      }
    }
  };

  compareObjects(obj1, obj2, prefix);
  return changedKeys;
};
// Function that returns the full object structure with only changed values
export const getChangedValues = (obj1, obj2) => {
  const result = {};

  for (const key in obj1) {
    if (
      typeof obj1[key] === 'object' &&
      obj1[key] !== null &&
      typeof obj2[key] === 'object' &&
      obj2[key] !== null
    ) {
      const nestedChanges = getChangedValues(obj1[key], obj2[key]);
      if (Object.keys(nestedChanges).length > 0) {
        result[key] = nestedChanges;
      }
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = obj2[key]; // Change this line
    }
  }

  for (const key in obj2) {
    if (!(key in obj1)) {
      result[key] = obj2[key];
    }
  }

  return result;
};

/**
 * Checks if an error message contains permission-related keywords
 * @param {Object} error - Error object from RTK Query
 * @returns {boolean} True if error is permission related
 */
export const isPermissionError = (error) => {
  const errorMessage = (error?.data?.detail || error?.data?.error || '').toLowerCase();
  return errorMessage.includes('permission') || errorMessage.includes('role');
};

/**
 * Checks if any errors in an error object are permission related
 * @param {Object} errors - Object containing multiple error objects
 * @returns {boolean} True if any error is permission related
 */
export const hasAnyPermissionErrors = (errors) => {
  if (!errors) return false;
  return Object.values(errors).some(isPermissionError);
};

/**
 * Checks if a value can be safely converted to a number
 * @param {any} value - Value to check
 * @returns {boolean} - True if value can be converted to number
 */
const isValidNumber = (value) => {
  if (value === null || value === undefined || value === '') return false;
  if (typeof value === 'boolean') return false;

  const num = Number(value);
  return !isNaN(num) && isFinite(num) && String(num) === String(value).trim();
};

/**
 * Converts string numbers to actual numbers at specified JSON path
 * @param {Object} data - The data object to process
 * @param {Array<string>} path - JSON path with * for array elements
 * @returns {Object} - Processed data with converted numbers
 */
export const convertStringNumberAtPath = (data, path) => {
  const processLevel = (obj, parts) => {
    if (!obj || typeof obj !== 'object') return obj;
    if (parts.length === 0) return obj;

    const [current, ...rest] = parts;

    if (current === '*') {
      if (!Array.isArray(obj)) return obj;
      return obj.map((item) => processLevel(item, rest));
    }

    if (rest.length === 0) {
      return {
        ...obj,
        [current]: isValidNumber(obj[current]) ? Number(obj[current]) : obj[current],
      };
    }

    return {
      ...obj,
      [current]: processLevel(obj[current], rest),
    };
  };

  return processLevel(data, path);
};

/**
 * Converts values in an object based on a provided schema map at a specific JSON path
 * and validates type compatibility
 *
 * @param {Object} data - The data object to process
 * @param {Array<string>} path - JSON path with * for array elements
 * @param {Object} schemaMap - Map of column names to their types
 * @returns {Object} - { data: processed data, errors: array of validation errors }
 */
export const convertValuesBySchema = (data, path, schemaMap) => {
  const errors = [];

  const processLevel = (obj, parts, currentPath = []) => {
    if (!obj || typeof obj !== 'object') return obj;
    if (parts.length === 0) return obj;

    const [current, ...rest] = parts;
    const newPath = [...currentPath, current];
    const isTargetPath = JSON.stringify(newPath) === JSON.stringify(path);

    // If we're at the target path, process col/value pairs
    if (isTargetPath && Array.isArray(obj)) {
      return obj.map((item) => {
        if (!item?.col || !('value' in item)) return item;
        const colType = schemaMap[item.col];
        if (!colType) return item;

        // Check for type mismatch - string value for INTEGER column
        if (colType === 'INTEGER' && typeof item.value === 'string') {
          const num = Number(item.value);
          if (isNaN(num)) {
            errors.push({
              col: item.col,
              value: item.value,
              type: colType,
              message: `Value "${item.value}" must be a number for column "${item.col}"`,
            });
            return item; // Return unchanged if invalid
          }
          return { ...item, value: num };
        }

        // Convert numbers to strings for STRING columns
        if (colType === 'STRING' && typeof item.value === 'number') {
          return { ...item, value: String(item.value) };
        }

        return item;
      });
    }

    // Handle array traversal
    if (current === '*') {
      if (!Array.isArray(obj)) return obj;
      return obj.map((item) => processLevel(item, rest, newPath));
    }

    // Continue traversing the object
    return {
      ...obj,
      [current]: processLevel(obj[current], rest, newPath),
    };
  };

  const processedData = processLevel(data, path);
  return { data: processedData, errors };
};

/**
 * Converts values to strings at specified JSON paths
 * @param {Object} data - The data object to process
 * @param {Array<Array<string>>} paths - Array of JSON paths with * for array elements
 * @returns {Object} - Processed data with string values
 */
export const convertJsonPathToString = (data, paths) => {
  if (!Array.isArray(data)) return data;

  const processLevel = (obj, parts) => {
    if (!obj || typeof obj !== 'object') return obj;
    if (parts.length === 0) return obj;

    const [current, ...rest] = parts;

    // Return unchanged if path doesn't exist
    if (!(current in obj) && current !== '*') return obj;

    if (current === '*') {
      if (!Array.isArray(obj)) return obj;
      return obj.map((item) => processLevel(item, rest));
    }

    if (rest.length === 0) {
      return {
        ...obj,
        [current]: String(obj[current] ?? ''),
      };
    }

    return {
      ...obj,
      [current]: processLevel(obj[current], rest),
    };
  };

  return paths.reduce(
    (result, path) => {
      return result.map((item) => processLevel(item, path));
    },
    [...data]
  );
};

/**
 * Converts and validates compare values based on target schema type
 * @param {Object} data - The data object to process
 * @param {Object} schemaMap - Map of column names to their types
 * @returns {Object} - { data: processed data, errors: array of validation errors }
 */
export const convertCompareValuesBySchema = (data, schemaMap) => {
  const errors = [];

  const processLevel = (obj, currentPath = []) => {
    if (!obj || typeof obj !== 'object') return obj;

    if (Array.isArray(obj)) {
      return obj.map((item) => processLevel(item, currentPath));
    }

    // Check if we're at a property condition
    if (obj.value && (obj.value.on_condition_true !== undefined || obj.value.col !== undefined)) {
      let targetType;
      let targetValue;
      let targetName;

      // Determine target type from either on_condition_true or col
      if (obj.value.on_condition_true !== undefined) {
        targetType = typeof obj.value.on_condition_true === 'number' ? 'INTEGER' : 'STRING';
        targetValue = obj.value.on_condition_true;
        targetName = 'on_condition_true';
      } else if (obj.value.col) {
        targetType = schemaMap[obj.value.col];
        targetValue = obj.value.col;
        targetName = `column "${obj.value.col}"`;
      }

      // If we have a compare value, validate and convert it
      if (obj.compare?.value !== undefined) {
        const compareValue = obj.compare.value;

        if (targetType === 'INTEGER' && typeof compareValue === 'string') {
          const num = Number(compareValue);
          if (isNaN(num)) {
            errors.push({
              value: compareValue,
              type: targetType,
              message: `Compare value "${compareValue}" must be a number to match ${targetName}`,
            });
            return obj;
          }
          return {
            ...obj,
            compare: { ...obj.compare, value: num },
          };
        }

        if (targetType === 'STRING' && typeof compareValue === 'number') {
          return {
            ...obj,
            compare: { ...obj.compare, value: String(compareValue) },
          };
        }
      }
    }

    // Continue traversing the object
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = processLevel(value, [...currentPath, key]);
      return acc;
    }, {});
  };

  const processedData = processLevel(data);
  return { data: processedData, errors };
};

/**
 * Validates values_to_ignore against the target schema type
 * @param {Object} data - The data object to process
 * @param {string} schema_template - The schema type (string_number)
 * @param {Object} schemaMap - Map of column names to their types
 * @returns {Object} - { data: processed data, errors: array of validation errors }
 */
export const validateValuesToIgnore = (data, schema_template, schemaMap) => {
  const errors = [];

  const processLevel = (obj) => {
    if (!obj || typeof obj !== 'object') return obj;

    // Check if we're at a property with when_to_update_property
    if (obj.when_to_update_property?.values_to_ignore && schema_template === 'string_number') {
      let targetType;
      const condition = obj.propertyConditionBlock?.propertyConditions?.[0]?.value;

      // Determine target type from either on_condition_true or col

      if (!isEmpty(condition?.on_condition_true)) {
        targetType = typeof condition.on_condition_true === 'number' ? 'INTEGER' : 'STRING';
      } else if (condition?.col && schemaMap) {
        targetType = schemaMap[condition.col];
      }

      const updatedValuesToIgnore = obj.when_to_update_property.values_to_ignore.map((value) => {
        if (targetType === 'INTEGER' && typeof value === 'string') {
          const num = Number(value);
          if (isNaN(num)) {
            errors.push({
              value,
              message: `values_to_ignore value "${value}" must be a number to match column type`,
            });
            return value; // Return unchanged if invalid
          }
          return num;
        }

        if (targetType === 'STRING' && typeof value === 'number') {
          return String(value);
        }

        return value;
      });

      return {
        ...obj,
        when_to_update_property: {
          ...obj.when_to_update_property,
          values_to_ignore: updatedValuesToIgnore,
        },
      };
    }

    // Continue traversing the object
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        acc[key] = processLevel(value);
        return acc;
      },
      Array.isArray(obj) ? [] : {}
    );
  };

  const processedData = processLevel(data);
  return { data: processedData, errors };
};
