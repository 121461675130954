import { z } from 'zod';

import DrawerButton from 'components/DrawerButton/DrawerButton';

/**
 * Schema for property builder.
 *
 */
const conditionSchema = z.object({
  col: z.string(),
  operator: z.enum(['equals', 'regex_contains', 'greater_than', 'less_than']),
  value: z.string(),
});

const formLogicBlockSchema = z.lazy(() =>
  z.object({
    andOr: z.enum(['and', 'or']),
    conditions: z.array(z.union([conditionSchema, formLogicBlockSchema])),
  })
);

const propertyConditionSchema = z.object({
  conditionBlock: formLogicBlockSchema,
  value: z.object({
    on_condition_true: z.number(),
    agg: z.enum(['count', 'sum', 'average']),
  }),
});

const propertyConditionBlockSchema = z.object({
  propertyConditions: z.array(propertyConditionSchema),
});

export const pipelineSchemaForm = z.object({
  id: z.number().nullable().optional(),
  test_boolean: z.boolean().default(false),
  name: z
    .string()
    .min(1, 'Name is required')
    .regex(
      /^[a-zA-Z][a-z0-9_]+[a-z0-9]$/,
      "Name must start with a letter and contain only letters, numbers, and underscores. (And can't end with an underscore)"
    ),
  gcloud_project_id: z.string().min(1, 'Google Cloud Project ID is required'),
  gcloud_region: z.string().min(1, 'Google Cloud Region is required'),
  bq_output_dataset: z.string().min(1, 'BigQuery Dataset is required'),
  run_hour: z.number().min(0).max(23).optional().default(6),
  timezone: z.string().default('+00:00'),
});

export const pipelineSchemaList = z.object({
  id: z.number().optional(),
  name: z.string(),
  gcloud_project_id: z.string(),
  gcloud_region: z.string(),
  bq_output_dataset: z.string(),
  run_hour: z.number().default(6),
  timezone: z.string(),
});

/**
 * Extra info
 *
 */
// Add a separate object for auto-set fields
export const autoSetFields = {
  gcloud_region: true,
};

export const fieldInfoFormPipeline = {
  id: { name: 'ID' },
  name: { name: 'Name', description: 'The name of the source' },
  gcloud_project_id: {
    name: 'Google Cloud Project',
    description: `Choose a Cloud Project. This is the project where your data will be stored. If you can see no options you'll need to create one/have it shared with you.`,
  },
  bq_output_dataset: {
    name: 'BigQuery Dataset',
    description: (
      <>
        <p>Choose a dataset. This is the dataset where your data will be stored.</p>
        <ul>
          <li>It needs to be in the same region as your GA4 export.</li>
          <li>
            You can check the region of your GA4 export in our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/ga4-bigquery-links"
              className="text-deep-sea-green-600 hover:text-deep-sea-green-500"
            >
              BQ Link Explorer
            </a>
            .
          </li>
          <li>
            You can create a <DrawerButton text="new dataset here." action="CREATE_DATASET_FORM" />
          </li>
          <li>
            We will also create a second table for the raw data at{' '}
            {String.raw`{your_dataset}_pl_raw`}.
          </li>
        </ul>
      </>
    ),
  },
  gcloud_region: {
    name: 'Google Cloud Region',
    description: 'You want all your data to be in the same region.',
  },
  timezone: {
    name: 'Timezone',
    description:
      'Select the timezone. This is only used to choose the time of day the pipeline runs.',
  },
  run_interval: {
    name: 'Pipelne Run Time (Hour)',
    description: 'The time of day the pipeline will run.',
  },
  run_hour: {
    name: 'Run Hour',
    description: 'Select the hour (0-23) when you want the pipeline to run daily',
  },
  currently_running: {
    name: 'Currently Running',
    description: 'Is the pipeline currently running?',
  },
  pipeline_status: {
    name: 'Pipeline Status',
    description: 'What is the status of the most recent pipeline run?',
  },
  gcloud_job_resource_name: {
    name: 'GCloud Job Name',
    description: 'The resource name of the Google Cloud job',
  },
  github_repo: {
    name: 'GitHub Repo',
    description: 'The GitHub repo with the DBT data.',
  },
  last_completed_run_status: {
    name: 'Last Completed Run Status',
    description: 'The status of the most recent pipeline run',
  },
};

export const fieldInfoDisplayPipeline = {
  id: { name: 'ID' },
  name: { name: 'Name', description: 'The name of the source' },
  gcloud_project_id: {
    name: 'Google Project',
    description: `Choose a Cloud Project. If you can see no options you'll need to create one/have it shared with you.`,
  },
  bq_output_dataset: {
    name: 'BigQuery Dataset',
    description: (
      <>
        <p>
          Choose a dataset where we&apos;ll put your output tables. It needs to be in the same
          region as your GA4 export.
        </p>
        <p>
          You can check your region in our support tools and{' '}
          {String.raw`{button:create a new dataset here:CREATE_DATASET_FORM}`}.
        </p>
        <p>
          Important second point: We will also create a second table for the raw data at{' '}
          {String.raw`{your_dataset}_pl_raw`}.
        </p>
      </>
    ),
  },
  gcloud_region: {
    name: 'Google Cloud Region',
    description: 'You want all your data to be in the same region.',
  },
  timezone: {
    name: 'Timezone',
    description:
      'Select the timezone. This is only used to choose the time of day the pipeline runs.',
  },
  run_interval: {
    name: 'Pipelne Run Time (Hour)',
    description: 'The time of day the pipeline will run.',
  },
  run_hour: {
    name: 'Run Hour',
    description: 'Select the hour (0-23) when you want the pipeline to run daily',
  },
  currently_running: {
    name: 'Currently Running',
    description: 'Is the pipeline currently running?',
  },
  pipeline_status: {
    name: 'Pipeline Status',
    description: 'What is the status of the most recent pipeline run?',
  },
  gcloud_job_resource_name: {
    name: 'GCloud Job Name',
    description: 'The resource name of the Google Cloud job',
  },
  github_repo: {
    name: 'GitHub Repo',
    description: 'The GitHub repo with the DBT data.',
  },
  last_completed_run_status: {
    name: 'Last Completed Run Status',
    description: 'The status of the most recent pipeline run',
  },
};
