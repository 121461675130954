import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';

import PipelineRunView from 'components/_displayModel/pipelineRuns/PipelineRunModelDisplay';
import { useGetPipelineRunByIdQuery } from 'services/rtkApi';
/**
 * Page component for viewing a pipeline run
 */
function PipelineRunViewPage() {
  const { id } = useParams();
  const { data: pipelineRun, isLoading, isError } = useGetPipelineRunByIdQuery(id);

  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-6 mt-6">
      <div className="flex items-center gap-4">
        <Link
          to={`/pipelines/${pipelineRun?.pipeline}`}
          className="text-gray-400 hover:text-gray-600"
        >
          <ArrowLeftIcon className="h-5 w-5" />
        </Link>
        <h1 className="text-xl font-semibold leading-7 text-gray-900">Pipeline Run Details</h1>
      </div>
      <PipelineRunView id={id} />
    </div>
  );
}

export default PipelineRunViewPage;
