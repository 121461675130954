import ChecklistStacked from 'components/ChecklistStacked/ChecklistStacked';
import {
  GOOGLE_API_PERMISSIONS_REGISTER,
  PIPELINE_CREATE_FORM,
  PLAN_MANAGEMENT,
  SOURCE_GA4_BIGQUERY_CREATE_FORM,
  SOURCE_GA4_BIGQUERY_UPDATE_FORM,
} from 'routes/constants';
import { useGetPipelinesQuery, useGetSourceGA4BigQueriesQuery } from 'services/rtkApi';

/**
 * GettingStarted component showing onboarding steps
 * @param {Object} props
 * @param {Object} props.onboardingStatus - Status of each step
 */
const OnboardingChecklist = ({ onboardingStatus }) => {
  const { data: pipelines_list } = useGetPipelinesQuery();
  const firstPipeline = pipelines_list?.results?.[0];

  const { data: ga4_sources_list } = useGetSourceGA4BigQueriesQuery(
    firstPipeline ? { pipeline: firstPipeline.id } : undefined
  );
  const firstGA4Source = ga4_sources_list?.results?.[0];

  const steps = [
    {
      id: 'choose_a_plan',
      name: 'Choose a Plan',
      description: 'Choose a plan for your account',
      isComplete: onboardingStatus.choose_a_plan,
      url: PLAN_MANAGEMENT,
    },
    {
      id: 'share_permissions',
      name: 'Share Permissions',
      description: 'Share access to your BigQuery with us',
      isComplete: onboardingStatus.share_permissions,
      url: GOOGLE_API_PERMISSIONS_REGISTER,
    },
    // {
    //   id: 'default_project',
    //   name: 'Set Default Project',
    //   description: 'Choose your primary project for analytics',
    //   isComplete: onboardingStatus.default_project,
    //   url: ACCOUNT_DEFAULTS,
    // },
    {
      id: 'create_pipeline',
      name: 'Create Pipeline',
      description: 'Set up your first data pipeline',
      isComplete: onboardingStatus.create_pipeline,
      url: PIPELINE_CREATE_FORM,
    },
    {
      id: 'add_ga4',
      name: 'Add a GA4 Property',
      description: 'Connect your Google Analytics 4 property',
      isComplete: onboardingStatus.add_ga4,
      url: firstPipeline
        ? SOURCE_GA4_BIGQUERY_CREATE_FORM.replace(':pipelineId', firstPipeline.id)
        : '#',
      disabled: !firstPipeline,
    },
    {
      id: 'complete_ga4_setup',
      name: 'Complete a GA4 Setup',
      description: 'Finish configuring your GA4 connection',
      isComplete: onboardingStatus.complete_ga4_setup,
      url:
        firstPipeline && firstGA4Source
          ? SOURCE_GA4_BIGQUERY_UPDATE_FORM.replace(':pipelineId', firstPipeline.id).replace(
              ':id',
              firstGA4Source.id
            )
          : '#',
      disabled: !firstPipeline || !firstGA4Source,
    },
  ];

  return (
    <ChecklistStacked
      items={steps}
      header={{
        title: 'Getting Started',
        description: 'Complete these steps to set up your analytics pipeline',
      }}
    />
  );
};

export default OnboardingChecklist;
