import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Shows a toast message with a clickable link
 * @param {Object} props Component props
 * @param {string} props.message Message to display in toast
 * @param {string} props.url URL to redirect to when clicked
 * @param {Object} [props.toastOptions] Additional react-toastify options
 */
export const showToastLink = ({ message, url, toastOptions = {} }) => {
  toast(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        window.location.href = url;
      }}
      style={{ cursor: 'pointer' }}
    >
      {message}
    </div>,
    {
      autoClose: 5000,
      ...toastOptions,
    }
  );
};

const ToasterLink = () => {
  return ReactDOM.createPortal(
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />,
    document.body
  );
};

export default ToasterLink;
