import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TextBubble from 'components/TextBubble/TextBubble';
import { showToastLink } from 'components/ToasterLink/ToasterLink';
import { GOOGLE_API_PERMISSIONS_REGISTER } from 'routes/constants';
import { getGoogleAPIAuthStatus } from 'services/auth/auth'; // Import the function
import {
  useGetCurrentAccountQuery,
  useGetGoogleBigQueryProjectsQuery,
  usePartialUpdateAccountMutation,
} from 'services/rtkApi';

/**
 * GoogleProjectSwitcher component for selecting a Google BigQuery project.
 * @returns {JSX.Element} The rendered component
 */
export default function GoogleProjectSwitcher() {
  const dispatch = useDispatch();
  const { control, setValue, watch } = useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const googleAPIAuthStatus = useSelector((state) => state.auth.googleAPIAuthStatus);
  const isAuthorized = googleAPIAuthStatus;

  // Get current user data
  const { data: currentAccount } = useGetCurrentAccountQuery();
  const [partialUpdateAccount] = usePartialUpdateAccountMutation();

  // Only call the query when we have permission
  const {
    data: projects,
    isLoading,
    error,
  } = useGetGoogleBigQueryProjectsQuery(undefined, {
    skip: !isAuthorized,
  });

  const watchedProject = watch('project');
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    const checkPermissions = async () => {
      const permissionStatus = await getGoogleAPIAuthStatus();
      setHasPermission(permissionStatus);
    };

    checkPermissions();
  }, []);

  // Move project initialization to separate effect that runs only after we have projects
  useEffect(() => {
    if (!projects || !hasPermission) return;

    if (!currentAccount?.default_gcloud_project_id && projects.length > 0) {
      setValue('project', projects[0].id);
    } else if (currentAccount?.default_gcloud_project_id) {
      setValue('project', currentAccount.default_gcloud_project_id);
    }
  }, [projects, currentAccount?.default_gcloud_project_id, setValue, hasPermission]);

  // Set currentProject when currentUser data arrives
  useEffect(() => {
    if (currentAccount?.default_gcloud_project_id) {
      setCurrentProject(currentAccount.default_gcloud_project_id);
    }
  }, [currentAccount]);

  /**
   * Handles project selection change
   */
  const handleProjectChange = async () => {
    try {
      const isFirstTimeSetup = !currentAccount?.default_gcloud_project_id;
      const response = await partialUpdateAccount({
        id: currentAccount.id,
        default_gcloud_project_id: watchedProject,
      }).unwrap();

      setCurrentProject(response.default_gcloud_project_id);

      if (isFirstTimeSetup) {
        showToastLink({
          message: 'Project saved! Please return to the homepage to continue setting up Pipeline.',
          url: '/',
          toastOptions: { autoClose: 5000 },
        });
      } else {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 1000);
      }
    } catch (error) {
      console.error('Failed to update project:', error);
    }
  };

  if (error) return <div>Error loading projects: {error.message}</div>;

  if (isLoading) {
    return (
      <div className="bg-white shadow sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:p-6">
          <LoadingSpinner text="Loading Google Projects..." />
        </div>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <CommonActionSingle
        title="Google BigQuery Permissions Required"
        description="In order to select your project you need to authorize Pipeline access."
        buttonName="Set Up Permissions"
        href={GOOGLE_API_PERMISSIONS_REGISTER}
        className="mb-6"
      />
    );
  }

  if (isAuthorized && projects?.length === 0) {
    return (
      <div className="bg-white shadow sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Select Google Project</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              You don&apos;t have access to any Google projects. You&apos;ll either need to set one
              up or have it shared with you. Please check out our documentation.
            </p>
          </div>
          <div className="mt-4">
            <Button
              href="https://pipedout.com/documentation/setup-instructions/creating-your-pipeline/connecting-ga4-to-bigquery/"
              variant="primary"
            >
              View Documentation
            </Button>
          </div>
        </div>
      </div>
    );
  }

  // console.log('watchedProject', watchedProject);
  // console.log('currentUser', currentUser);
  // console.log('currentProject', currentProject);
  // console.log(watchedProject, currentProject);
  // console.log(watchedProject !== currentProject);
  return (
    <div className="bg-white shadow sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Select Google Project</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Select any Google Cloud project. This stops Google from complaining when we ask for
            things. (Prevents rate limiting) .
          </p>
        </div>
        <div className="mt-4">
          {hasPermission ? (
            <>
              <div className="flex items-start gap-6 bg-gray-50 p-4 pb-0 rounded-md mb-6">
                <div className="flex-shrink-0">
                  <div className="text-sm font-medium text-gray-700 h-[21px]">Current Project</div>
                  <div className="mt-2 px-3 py-2 bg-gray-50 border border-gray-300 rounded-md text-gray-500 text-sm">
                    {projects?.find((p) => p.id === currentProject)?.name || 'None selected'}
                  </div>
                </div>
                <div className="flex-grow border-l border-gray-200 pl-6 mb-4">
                  <FormSelect
                    fieldKey="project"
                    label="Select a project"
                    control={control}
                    errors={{}}
                    options={
                      projects?.map((project) => ({ value: project.id, label: project.name })) || []
                    }
                    isLoading={isLoading}
                    className="w-full max-w-xs mb-4"
                  />
                </div>
              </div>
              <Button onClick={handleProjectChange} loading={isLoading} variant="primary">
                Confirm Selection
              </Button>
              {isSuccess && (
                <div className="mt-2">
                  <TextBubble color="green" text="Project selected!" />
                </div>
              )}
            </>
          ) : (
            <Button href={GOOGLE_API_PERMISSIONS_REGISTER} variant="primary">
              Grant Permissions
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
