import { useDispatch } from 'react-redux';

import { openDrawer } from 'services/drawers/drawerSlice';

const InlineButton = ({ text, action }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openDrawer({ content: action }));
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="text-deep-sea-green-600 hover:text-deep-sea-green-500"
    >
      {text}
    </button>
  );
};

export default InlineButton;
