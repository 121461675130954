import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { rehydrateAuthToken } from 'services/auth/authApiInitialize';
import { setGoogleAPIAuthStatus } from 'services/auth/authSlice';
import api from 'services/rtkApi';

/**
 * Hook to handle app initialization flows including Google API authentication
 * @returns {Object} Object containing any error states from initialization
 */
export const useAppInitialization = () => {
  const dispatch = useDispatch();
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const { data, error } = api.endpoints.getGoogleAPIAuthStatus.useQuery(undefined, {
    skip: !refreshToken,
  });

  useEffect(() => {
    try {
      rehydrateAuthToken();

      if (data) {
        dispatch(setGoogleAPIAuthStatus(data.is_authenticated));
      }
    } catch (err) {
      console.error('Failed to initialize app:', err);
      dispatch(setGoogleAPIAuthStatus(false));
    }
  }, [dispatch, data]);

  return { error };
};
