import { useCallback } from 'react';

import useDynamicForeignKeyOptions from 'hooks/forms/useDynamicForeignKeyOptions';

/**
 * Hook to manage dynamic options for GA4 BigQuery forms
 * @param {Array} foreignKeyOptions - The foreign key options for the form
 * @param {Array} dynamicFieldOptionsConfig - Configuration for dynamic field options
 * @returns {Object} An object containing the postOnChangeOptions function
 */
const useSourceGA4BigQueryDynamicOptions = (foreignKeyOptions, dynamicFieldOptionsConfig) => {
  const { updateDynamicOptions } = useDynamicForeignKeyOptions(
    foreignKeyOptions,
    dynamicFieldOptionsConfig
  );

  /**
   * This function needs to return an array of objects with path and options
   *
   * It needs to return all of the options plus whatever has changed.
   */
  const postOnChangeOptions = useCallback(
    (formValues, changedFields, changedPaths, currentForeignKeyOptions) => {
      // console.log('postOnChangeOptions called', {
      //   formValues,
      //   changedFields,
      //   changedPaths,
      //   currentForeignKeyOptions,
      // });

      const newOptions = [...currentForeignKeyOptions];
      updateDynamicOptions(formValues, changedPaths);

      /**
       * Recursively search for matching paths and update dependent fields
       * @param {Object} obj - The object to search through
       * @param {Array} currentPath - The current path being searched
       */
      const updateDependentFields = (obj, currentPath = []) => {
        if (typeof obj !== 'object' || obj === null) return;

        Object.entries(obj).forEach(([key, value]) => {
          const newPath = [...currentPath, isNaN(key) ? key : parseInt(key, 10)];

          // Pass in the path we want to match
          // And the new value we're matching at the end of that path.

          dynamicFieldOptionsConfig.forEach((config) => {
            if (pathsMatch(config.field, newPath)) {
              const matchingOption = config.options.find((option) => option.value === value);

              // If we get a matching option then we need to update the foreign key options
              // for the dependent field.
              if (matchingOption) {
                const dependentPath = replacePlaceholders(config.dependentField, newPath);

                const existingOptionIndex = newOptions.findIndex((option) =>
                  arraysEqual(option.key, dependentPath)
                );

                if (existingOptionIndex !== -1) {
                  newOptions[existingOptionIndex] = {
                    key: dependentPath,
                    options: matchingOption.dependentOptions,
                  };
                } else {
                  newOptions.push({
                    key: dependentPath,
                    options: matchingOption.dependentOptions,
                  });
                }
              }
            }
          });

          updateDependentFields(value, newPath);
        });
      };

      updateDependentFields(formValues);

      return newOptions;
    },
    [updateDynamicOptions, dynamicFieldOptionsConfig]
  );

  return { postOnChangeOptions };
};

/**
 * Check if two paths match, considering wildcards
 * @param {Array} configPath - The path from the config
 * @param {Array} actualPath - The actual path in the object
 * @returns {boolean} True if paths match, false otherwise
 */
const pathsMatch = (configPath, actualPath) => {
  if (configPath.length !== actualPath.length) return false;
  return configPath.every(
    (segment, index) =>
      segment === '*' ||
      segment === actualPath[index] ||
      (segment === '*' && typeof actualPath[index] === 'number')
  );
};

/**
 * Replace wildcards in the config path with actual values
 * @param {Array} configPath - The path from the config
 * @param {Array} actualPath - The actual path in the object
 * @returns {Array} The config path with wildcards replaced
 */
const replacePlaceholders = (configPath, actualPath) => {
  return configPath.map((segment, index) => (segment === '*' ? actualPath[index] : segment));
};

/**
 * Compare two arrays for equality
 * @param {Array} arr1 - First array
 * @param {Array} arr2 - Second array
 * @returns {boolean} True if arrays are equal, false otherwise
 */
const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};

export default useSourceGA4BigQueryDynamicOptions;
